import React, { useMemo, useCallback, useState, useEffect } from "react"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../../UIContext"
import { makeStyles } from "@material-ui/core"
import Switch from "@mui/material/Switch"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { Modal } from "../../../../globalUI/snackBar/Modal"
import ScheduleModal from "../../_partials/ScheduleModal"
import RenderRecipientsSummary from "./_partials/RenderRecipientsSummary"
import RenderMessagePriorityGroupButton from "../../_partials/RenderMessagePriorityGroupButton"
import RenderSendMessageForm from "./_partials/RenderSendMessageForm"
import { sendViaAPP } from "../../_utils/useSendViaAPP"
import { sendViaSMS } from "../../_utils/useSendViaSMS"
import { BUTTONS } from "../../../../_enums/buttons"
import { RenderSvgIcon } from "../../_partials/RenderSvgIcon"

const useStyles = makeStyles(theme => ({
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	container: {
		padding: "1rem",
		backgroundColor: "#FFFFFF",
		filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))"
	},
	selectedApp: {
		borderBottom: "1px solid #2673F0",
		fontWeight: "700"
	},
	selectedSms: {
		borderBottom: "1px solid #8680FF",
		fontWeight: "700"
	},
	switchSms: {
		"& .MuiSwitch-thumb": {
			backgroundColor: "#8680FF !important"
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#cfccff !important"
		}
	},
	switchApp: {
		"& .MuiSwitch-thumb": {
			backgroundColor: "#2673F0 !important"
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#a8c7f9 !important"
		}
	}
}))

const DESCRIPTION_TEXT =
	"Alert will be sent to all the selected people on site. If a specific person is not selected, the alert will be sent to people on-site today."
const TITLE_TEXT = "Send Message"
const BACK_TEXT = "Back"
const SEND_TEXT = "Send"
const SMALL_FONT_SIZE = "small"
const APP_MESSAGE_TYPE = "APP"
const SMS_MESSAGE_TYPE = "SMS"
const ICON_PATH = [
	"M17.1344 3.26944C17.3688 3.50386 17.5005 3.8218 17.5005 4.15332V7.90332H16.2505V4.15332H13.7505V5.40332H12.5005V4.15332H7.50049V5.40332H6.25049V4.15332H3.75049V16.6533H6.25049V17.9033H3.75049C3.41897 17.9033 3.10103 17.7716 2.8666 17.5372C2.63218 17.3028 2.50049 16.9848 2.50049 16.6533V4.15332C2.50049 3.8218 2.63218 3.50386 2.8666 3.26944C3.10103 3.03502 3.41897 2.90332 3.75049 2.90332H6.25049V1.65332H7.50049V2.90332H12.5005V1.65332H13.7505V2.90332H16.2505C16.582 2.90332 16.9 3.03502 17.1344 3.26944ZM13.1255 19.1533C12.1366 19.1533 11.1699 18.8601 10.3476 18.3107C9.52539 17.7613 8.88453 16.9804 8.50609 16.0667C8.12765 15.1531 8.02864 14.1478 8.22156 13.1779C8.41449 12.208 8.89069 11.3171 9.58996 10.6178C10.2892 9.91853 11.1801 9.44232 12.15 9.2494C13.1199 9.05647 14.1253 9.15549 15.0389 9.53393C15.9525 9.91236 16.7334 10.5532 17.2828 11.3755C17.8322 12.1977 18.1255 13.1644 18.1255 14.1533C18.1255 15.4794 17.5987 16.7512 16.661 17.6889C15.7233 18.6265 14.4516 19.1533 13.1255 19.1533ZM13.1255 10.4033C12.3838 10.4033 11.6588 10.6233 11.0421 11.0353C10.4254 11.4474 9.94477 12.033 9.66094 12.7183C9.37711 13.4035 9.30285 14.1575 9.44755 14.8849C9.59224 15.6123 9.94939 16.2805 10.4738 16.805C10.9983 17.3294 11.6665 17.6866 12.3939 17.8313C13.1213 17.976 13.8753 17.9017 14.5606 17.6179C15.2458 17.334 15.8314 16.8534 16.2435 16.2367C16.6556 15.62 16.8755 14.895 16.8755 14.1533C16.8755 13.1588 16.4804 12.2049 15.7771 11.5017C15.0739 10.7984 14.1201 10.4033 13.1255 10.4033ZM14.1192 16.0283L12.5005 14.4096V11.6533H13.7505V13.8971L15.0005 15.1471L14.1192 16.0283Z"
]
const MODAL_SIZE = "sm"
const MODAL_TITLE = "Schedule Message"
const NORMAL_VALUE = "normal"
const SCHEDULED_COLOR = "#2e9f29"
const NOT_SCHEDULED_COLOR = "#2673F0"

const SendMessage = ({ handleChange }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const UIContext = useUIContext()
	const UIProps = useMemo(
		() => ({
			membersToSendMessage: UIContext.membersToSendMessage,
			selectedValues: UIContext.selectedValues,
			sitesValue: UIContext.sitesValue
		}),
		[UIContext]
	)

	const { selectedCustomer, selectedSite, user, allSites } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			selectedCustomer: state.profile?.currentCustomer,
			allSites: state.manageSites?.sites,
			user: state.auth?.user
		}),
		shallowEqual
	)

	const [alignment, setAlignment] = useState(NORMAL_VALUE)
	const [messageType, setMessageType] = useState(APP_MESSAGE_TYPE)
	const [inputMessage, setInputMessage] = useState("")
	const [inputSubject, setInputSubject] = useState("")
	const [invalidMembers, setInvalidMembers] = useState([])
	const [validMembers, setValidMembers] = useState([])
	const [groups, setGroups] = useState([])
	const [checked, setChecked] = useState(true)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [failure, setFailure] = useState(false)
	const [inputDate, setInputDate] = useState(null)
	const [inputTime, setInputTime] = useState(null)
	const [showSchedule, setShowSchedule] = useState(false)

	const isScheduled = inputDate && inputTime
	const isScheduledColor = isScheduled ? SCHEDULED_COLOR : NOT_SCHEDULED_COLOR

	const handleChangeMessageType = event => {
		setChecked(event.target.checked)

		if (event.target.checked) setMessageType(APP_MESSAGE_TYPE)
		else setMessageType(SMS_MESSAGE_TYPE)
	}

	const handleButtonGroupChange = useCallback((event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment)
		}
	}, [])

	useEffect(() => {
		if (!UIProps.membersToSendMessage || UIProps.membersToSendMessage.length === 0) return

		if (messageType === SMS_MESSAGE_TYPE) {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => person.personalData?.phoneNumber)
			setValidMembers(v)
		}
		if (messageType === APP_MESSAGE_TYPE) {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => person.UID)
			setValidMembers(v)
		}
	}, [UIProps.membersToSendMessage, messageType])

	useEffect(() => {
		if (!validMembers) return
		if (messageType === SMS_MESSAGE_TYPE) {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => !person.personalData?.phoneNumber)
			setInvalidMembers(v)
		}
		if (messageType === APP_MESSAGE_TYPE) {
			const v =
				UIProps.membersToSendMessage?.length > 0 &&
				UIProps.membersToSendMessage.filter(person => !person.UID)
			setInvalidMembers(v)
		}
	}, [validMembers])

	useEffect(() => {
		if (!UIProps.selectedValues || UIProps.selectedValues.length === 0) return

		const flattened = Object.values(UIProps.selectedValues).flat()
		setGroups(flattened)
	}, [UIProps.selectedValues])

	const headerChildren = (
		<div
			className="col"
			style={{
				textAlignLast: "end",
				display: "flex",
				justifyContent: "end",
				alignItems: "center"
			}}
		>
			<div
				className={`${classes.label} ${
					messageType === SMS_MESSAGE_TYPE && classes.selectedSms
				} p-0`}
			>
				SMS
			</div>
			<Switch
				checked={checked}
				className={messageType === SMS_MESSAGE_TYPE ? classes.switchSms : classes.switchApp}
				onChange={handleChangeMessageType}
				inputProps={{ "aria-label": "controlled" }}
			/>
			<div
				className={`${classes.label} ${
					messageType === APP_MESSAGE_TYPE && classes.selectedApp
				} p-0`}
			>
				APP
			</div>
		</div>
	)

	return (
		<div className={classes.container} data-testid="send-message-component">
			<div className="row">
				<div className="col-4">
					<RenderRecipientsSummary
						allSites={allSites}
						validMembers={validMembers}
						invalidMembers={invalidMembers}
						sitesValue={UIProps.sitesValue}
						membersToSendMessage={UIProps.membersToSendMessage}
						groups={groups}
						messageType={messageType}
					/>
				</div>
				<div className="col">
					<RenderMessagePriorityGroupButton
						title={TITLE_TEXT}
						description={DESCRIPTION_TEXT}
						headerChildren={headerChildren}
						handleButtonGroupChange={handleButtonGroupChange}
						alignment={alignment}
					/>

					<RenderSendMessageForm
						inputSubject={inputSubject}
						setInputSubject={setInputSubject}
						inputMessage={inputMessage}
						setInputMessage={setInputMessage}
						classes={classes}
					/>
				</div>
			</div>
			<div className="row justify-content-between" style={{ padding: "0 1rem 0 1rem" }}>
				<Button
					color={BUTTONS.COLORS.PRIMARY}
					variant={BUTTONS.VARIANTS.OUTLINED}
					text={BACK_TEXT}
					startIcon={<KeyboardArrowLeftIcon fontSize={SMALL_FONT_SIZE} />}
					onClick={e => handleChange(e, 1)}
				/>
				<div className="d-flex">
					<Button
						color={BUTTONS.COLORS.PRIMARY}
						variant={BUTTONS.VARIANTS.CONTAINED}
						disabled={!inputSubject.trim() || !inputMessage.trim()}
						onClick={() => {
							setLoading(true)
							handleBroadcast(
								selectedCustomer,
								selectedSite,
								user,
								alignment,
								inputDate,
								inputTime,
								validMembers,
								messageType,
								inputMessage,
								inputSubject,
								setSuccess,
								setFailure,
								setLoading,
								dispatch
							)
						}}
						text={SEND_TEXT}
						needsLoadingBar
						loading={loading}
						success={success}
						failure={failure}
						setSuccess={setSuccess}
						setFailure={setFailure}
					/>
					{messageType === APP_MESSAGE_TYPE && (
						<Button
							color={
								isScheduled ? BUTTONS.COLORS.SUCCESS_LIGHT : BUTTONS.COLORS.PRIMARY_LIGHT
							}
							variant={BUTTONS.VARIANTS.CONTAINED}
							onClick={() => {
								setShowSchedule(true)
							}}
							className="ml-2"
							startIcon={
								<RenderSvgIcon iconConfig={{ fill: isScheduledColor, paths: ICON_PATH }} />
							}
						/>
					)}
				</div>
			</div>
			<Modal
				size={MODAL_SIZE}
				show={showSchedule}
				onHide={() => {
					setShowSchedule(false)
				}}
				title={MODAL_TITLE}
			>
				<ScheduleModal
					inputDate={inputDate}
					setInputDate={setInputDate}
					inputTime={inputTime}
					setInputTime={setInputTime}
					setShowSchedule={setShowSchedule}
				/>
			</Modal>
		</div>
	)
}

export default SendMessage

async function handleBroadcast(
	selectedCustomer,
	selectedSite,
	user,
	alignment,
	inputDate,
	inputTime,
	validMembers,
	messageType,
	inputMessage,
	inputSubject,
	setSuccess,
	setFailure,
	setLoading,
	dispatch
) {
	if (messageType === SMS_MESSAGE_TYPE) {
		await sendViaSMS({
			people: validMembers,
			inputMessage,
			customerId: selectedCustomer.id,
			customerName: selectedCustomer.customerInfo?.name,
			siteId: selectedSite.id,
			siteName: selectedSite.siteInfo?.name,
			user,
			setSuccess,
			setFailure,
			dispatch
		})
		setLoading(false)
	} else {
		await sendViaAPP({
			people: validMembers,
			inputMessage,
			inputSubject,
			alignment,
			inputDate,
			inputTime,
			user,
			customerId: selectedCustomer.id,
			setSuccess,
			setFailure,
			dispatch
		})
		setLoading(false)
	}
}
