import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"
import DateFilter from "./DateFilter"
import HistoryList from "../_partials/HistoryList"
import { RenderSvgIcon } from "../_partials/RenderSvgIcon"

import { useFetchHistory } from "../_hooks/useFetchHistory"
import { useResendMessage } from "../_hooks/useResendMessage"
import { HISTORY } from "../_enums/history"
import { useStyles } from "../_partials/StylesOverride"

export function HistoryCard({ showAnnouncements, selectedCustomer, user }) {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [dateInputValue, setDateInputValue] = useState(null)
	const [timeInputValue, setTimeInputValue] = useState({ start: null, end: null })
	const [histories, setHistories] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isResending, setIsResending] = useState(false)

	const handleSubmit = async () => {
		setIsLoading(true)
		const historyData = await useFetchHistory(
			setIsLoading,
			dateInputValue,
			timeInputValue,
			selectedCustomer,
			user,
			dispatch,
			showAnnouncements
		)
		setHistories(historyData)
		setIsLoading(false)
	}
	const handleResend = async id => {
		await useResendMessage({ id, selectedCustomer, user, dispatch, setIsResending })
	}

	useEffect(() => {
		if (histories?.length > 0) setHistories([])
	}, [showAnnouncements])

	return (
		<div className={classes.container} data-testid="history-card">
			<div className="row mb-4">
				<div className="col">
					<RenderSvgIcon
						iconConfig={{
							paths: HISTORY.STYLES.ICONS.MESSAGE_HISTORY_ICON.PATHS,
							fill: HISTORY.STYLES.ICONS.PRIMARY_FILL
						}}
						style={{
							marginRight: HISTORY.STYLES.ICONS.MARGIN_RIGHT,
							fontSize: HISTORY.STYLES.ICONS.FONT_SIZE
						}}
					/>
					<span className={classes.title} data-testid="history-title">
						{showAnnouncements
							? HISTORY.TEXTS.TITLES.ANNOUNCEMENTS_TITLE
							: HISTORY.TEXTS.TITLES.MESSAGES_TITLE}
					</span>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col">
					<DateFilter
						dateInputValue={dateInputValue}
						setDateInputValue={setDateInputValue}
						timeInputValue={timeInputValue}
						setTimeInputValue={setTimeInputValue}
						handleSubmit={handleSubmit}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<HistoryList
						histories={histories}
						handleResend={handleResend}
						isResending={isResending}
						showAnnouncements={showAnnouncements}
					/>
				</div>
			</div>
			{isLoading && <ModalProgressBar variant="query" />}
		</div>
	)
}

export default HistoryCard
