import React from "react"
import { Button as MUIButton, LinearProgress, makeStyles } from "@material-ui/core"
import Box from "@mui/material/Box"
import clsx from "clsx"
import PropTypes from "prop-types"
import { green, red } from "@mui/material/colors"

const useStyles = makeStyles(theme => ({
	button: {
		minWidth: "0px",
		borderRadius: "4px",
		position: "relative",
		"&.MuiButton-outlined": {
			borderColor: "#2673F0"
		},
		"& .MuiButton-startIcon": {
			marginLeft: "0",
			marginRight: "0"
		}
	},
	defaultButton: {
		boxShadow: "none",
		lineHeight: "21px"
	},
	tabButton: {
		padding: "15px",
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: "4px",
		borderTopRightRadius: "4px",
		boxShadow: "inset 5px -5px 5px -3px rgb(0 0 0 / 10%)",
		"&:hover": {
			backgroundColor: "#f7f7f7 !important"
		}
	},

	label: {
		fontFamily: "Poppins",
		letterSpacing: "0.04em"
	},
	defaultButtonLabel: {
		fontWeight: "700",
		lineHeight: "21px"
	},
	tabButtonLabel: {
		color: "#4A4A68",
		textTransform: "capitalize",
		fontSize: "15px",
		fontWeight: "600",
		lineHeight: "160%"
	},

	activeTab: {
		boxShadow: "0px 10px 5px 2px rgba(0, 0, 0, 0.1)"
	},
	inactiveLabel: {
		color: "#8C8CA1"
	},

	labelContentLeft: {
		"& .MuiButton-label": {
			justifyContent: "left"
		}
	},
	labelContentRight: {
		"& .MuiButton-label": {
			justifyContent: "right"
		}
	},
	iconColorGrey: {
		"& .MuiButton-startIcon > svg": {
			fill: "#8C8CA1"
		}
	},

	primaryLight: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: "#acd1fa !important"
		}
	},
	warning: {
		backgroundColor: theme.palette.warning.main,
		color: "#fff",
		"&:hover": {
			backgroundColor: "#f4a623 !important"
		}
	},
	warningLight: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.main,
		"&:hover": {
			backgroundColor: "#f8cd82 !important"
		}
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: "#FFF",
		"&:hover": {
			backgroundColor: "#c34415 !important"
		}
	},
	errorLight: {
		backgroundColor: theme.palette.error.light,
		color: "#E65019",
		"&:hover": {
			backgroundColor: "#f5c6b3 !important"
		}
	},
	successLight: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.main,
		"&:hover": {
			backgroundColor: "#77db72 !important"
		}
	},
	greyLight: {
		backgroundColor: theme.palette.grey.light,
		color: theme.palette.grey.main,
		borderColor: theme.palette.grey.main,
		"&:hover": {
			color: "#FFF !important",
			backgroundColor: theme.palette.primary.main
		}
	},
	failure: {
		backgroundColor: red[500],
		color: "#FFF",
		"&:hover": {
			backgroundColor: red[700] + " !important"
		}
	},
	loadingBar: {
		color: green[500],
		position: "absolute",
		bottom: -6,
		left: 0,
		right: 0,
		zIndex: 1
	}
}))

export const Button = ({
	text,
	className,
	color,
	activeTab,
	contentAlignment,
	needsLoadingBar,
	loading,
	success,
	failure,
	setSuccess,
	setFailure,
	isTabButton = false,
	onClick,
	...props
}) => {
	const classes = useStyles({ color })

	React.useEffect(() => {
		let resetTimer
		if (success) {
			resetTimer = setTimeout(() => {
				setSuccess(false)
			}, 2000)
		}
		return () => clearTimeout(resetTimer)
	}, [success, setSuccess])

	React.useEffect(() => {
		let resetTimer
		if (failure) {
			resetTimer = setTimeout(() => {
				setFailure(false)
			}, 2000)
		}
		return () => clearTimeout(resetTimer)
	}, [failure, setFailure])

	const button = (
		<MUIButton
			color={color ? color : "default"}
			className={clsx(
				classes.button,
				className,
				isTabButton
					? clsx(
							classes.tabButton,
							contentAlignment === "left" && classes.labelContentLeft,
							contentAlignment === "right" && classes.labelContentRight,
							!activeTab && classes.iconColorGrey,
							activeTab && classes.activeTabButton
					  )
					: clsx(
							classes.defaultButton,
							classes[color],
							success && classes.success,
							failure && classes.failure
					  )
			)}
			style={{
				justifyContent: contentAlignment || "center"
			}}
			onClick={onClick}
			{...props}
		>
			{text && (
				<span
					style={{
						marginLeft: props.startIcon && "10px",
						marginRight: props.endIcon && "10px"
					}}
					className={clsx(
						classes.label,
						isTabButton ? classes.tabButtonLabel : classes.defaultButtonLabel,
						isTabButton && !activeTab && classes.inactiveLabel
					)}
				>
					{text}
				</span>
			)}
			{props.children}
		</MUIButton>
	)

	return needsLoadingBar ? (
		<Box sx={{ position: "relative" }}>
			{button}
			{loading && <LinearProgress className={classes.loadingBar} />}
		</Box>
	) : (
		button
	)
}

Button.propTypes = {
	text: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.oneOf(["primary", "secondary", "success", "error", "warning", "default"]),
	activeTab: PropTypes.bool, // Highlights button if it's an active tab
	contentAlignment: PropTypes.oneOf(["left", "center", "right"]), // Alignment for tab content
	needsLoadingBar: PropTypes.bool, // Adds loading bar
	loading: PropTypes.bool, // Shows loading state
	success: PropTypes.bool, // Success state
	failure: PropTypes.bool, // Failure state
	onClick: PropTypes.func,
	children: PropTypes.node
}
