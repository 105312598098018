import React, { useState } from "react"
import { makeStyles, ListItemIcon, List, ListItem, ListItemText, Collapse } from "@material-ui/core"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"
import Alert from "@material-ui/lab/Alert"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import ViewListIcon from "@material-ui/icons/ViewList"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import RenderImagePreview from "../../../../../_partials/RenderImagePreview"

const useStyles = makeStyles(theme => ({
	title: {
		color: "#444",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "600",
		lineHeight: "160%",
		textTransform: "capitalize",
		padding: "1rem 0"
	}
}))

const TITLE_SUMMARY = "Summary"
const LABEL_NAME = "Name: "
const SELECTED_SITES_LABEL = "Sites Selected"
const SELECTED_PEOPLE_LABEL = "People Selected"
const SELECTED_GROUPS_LABEL = "Groups Selected"

const RenderRecipientsSummary = ({
	allSites,
	validMembers,
	invalidMembers,
	sitesValue,
	membersToSendMessage,
	groups,
	messageType
}) => {
	const classes = useStyles()

	const [openList, setOpenList] = useState(true)

	const handleClickList = () => {
		setOpenList(!openList)
	}

	return (
		<>
			<span className={classes.title}>{TITLE_SUMMARY}</span>

			{sitesValue?.length > 0 && (
				<div
					style={{
						marginTop: "10px",
						marginBottom: "10px",
						borderRadius: "5px",
						border: "1px solid #2673F0",
						margin: "2rem 0",
						padding: "10px"
					}}
					className="row"
				>
					<div className="col">
						<div className="row" style={{ paddingLeft: "12.5px", alignItems: "center" }}>
							<div
								className="col-4 d-flex"
								style={{ position: "relative", overflow: "auto", height: "31px" }}
							>
								{sitesValue?.slice(0, 6).map((data, index) => {
									const site = allSites?.find(aSite => aSite.id === data)
									const imgPath = site?.siteInfo?.imagePaths?.[0]

									return (
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="quick-actions-tooltip">
													{LABEL_NAME} {site?.siteInfo?.name}
												</Tooltip>
											}
										>
											<RenderImagePreview index={index} imgPath={imgPath} />
										</OverlayTrigger>
									)
								})}
							</div>
							<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
								{sitesValue.length}
							</div>
							<div className="col select-counter">{SELECTED_SITES_LABEL}</div>
						</div>
					</div>
				</div>
			)}
			{validMembers?.length > 0 && (
				<div
					style={{
						marginTop: "10px",
						marginBottom: "10px",
						borderRadius: "5px",
						border: "1px solid #2673F0",
						margin: "2rem 0",
						padding: "10px"
					}}
					className="row"
				>
					<div className="col">
						<div className="row" style={{ paddingLeft: "12.5px", alignItems: "center" }}>
							<div
								className="col-4 d-flex"
								style={{ position: "relative", overflow: "auto", height: "31px" }}
							>
								{validMembers?.slice(0, 6).map((person, index) => {
									const imgPath = person?.personalData?.picture

									return (
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="quick-actions-tooltip">
													{LABEL_NAME} {person?.personalData?.name}
												</Tooltip>
											}
										>
											<RenderImagePreview index={index} imgPath={imgPath} />
										</OverlayTrigger>
									)
								})}
							</div>
							<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
								{validMembers.length}
							</div>
							<div className="col select-counter">{SELECTED_PEOPLE_LABEL}</div>
						</div>
					</div>
				</div>
			)}
			{groups?.length > 0 && (
				<div
					style={{
						marginTop: "10px",
						marginBottom: "10px",
						borderRadius: "5px",
						border: "1px solid #2673F0",
						margin: "2rem 0",
						padding: "10px"
					}}
					className="row"
				>
					<div className="col">
						<div className="row" style={{ paddingLeft: "12.5px", alignItems: "center" }}>
							<div
								className="col-4 d-flex"
								style={{ position: "relative", overflow: "auto", height: "31px" }}
							>
								{groups &&
									groups.slice(0, 6).map((index, group) => {
										return (
											<OverlayTrigger
												placement="bottom"
												overlay={
													<Tooltip id="quick-actions-tooltip">
														{LABEL_NAME} {group}
													</Tooltip>
												}
											>
												<div
													key={index}
													className="icon-preview"
													style={{
														position: "absolute",
														left: `${index * 10}px`
													}}
												>
													<SVG src={"/media/crowdkeep/person.svg"} />
												</div>
											</OverlayTrigger>
										)
									})}
							</div>
							<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
								{groups.length}
							</div>
							<div className="col select-counter">{SELECTED_GROUPS_LABEL}</div>
						</div>
					</div>
				</div>
			)}

			{invalidMembers?.length > 0 && (
				<>
					<div className="mb-4">
						<Alert
							severity="warning"
							style={{
								color: "rgb(102, 60, 0)",
								backgroundColor: "rgb(255, 244, 229)",
								alignItems: "center"
							}}
						>
							Attention:{" "}
							<b>
								{invalidMembers?.length}/{membersToSendMessage?.length}
							</b>{" "}
							will not receive a message. <br />
							To receive a message via <b>{messageType}</b>, all recipients <br />
							should have a valid <b>{messageType === "SMS" ? "Phone Number" : "UID"}</b>.
						</Alert>
					</div>

					<div className="mb-4">
						<List aria-labelledby="nested-list-subheader">
							<ListItem button onClick={handleClickList}>
								<ListItemIcon>
									<ViewListIcon />
								</ListItemIcon>
								<ListItemText
									primary={`List of people without ${
										messageType === "SMS" ? "Phone Number" : "UID"
									}`}
								/>
								{openList ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse
								in={openList}
								timeout="auto"
								unmountOnExit
								style={{
									maxHeight: "100px",
									overflowX: "scroll"
								}}
							>
								<List component="div" disablePadding>
									{invalidMembers?.length > 0 &&
										invalidMembers.map(person => {
											return (
												<ListItem key={person?.id} button>
													<ListItemIcon>
														<SVG
															style={{
																fill: "#1C1C3B"
															}}
															src={toAbsoluteUrl("/media/crowdkeep/person.svg")}
														/>
													</ListItemIcon>
													<ListItemText primary={person?.personalData?.name || "-"} />
												</ListItem>
											)
										})}
								</List>
							</Collapse>
						</List>
					</div>
				</>
			)}
		</>
	)
}

export default RenderRecipientsSummary
