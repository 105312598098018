import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Button } from "./Button"
import { BUTTONS } from "../_enums/buttons"

const useStyles = makeStyles(theme => ({
	stepper: {
		"& .MuiStepLabel-iconContainer": {
			paddingRight: "12px"
		},
		"& .MuiSvgIcon-root": {
			width: "1.3em",
			height: "1.3em",
			fontWeight: "bold"
		},
		"& .MuiStepLabel-label": {
			fontFamily: "Poppins",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "24px"
		},
		"& .MuiStepLabel-label.MuiStepLabel-active": {
			color: "#2673F0"
		},
		"& .MuiStepLabel-label.MuiStepLabel-completed": {
			color: "#2673F0"
		}
	}
}))

const BACK_TEXT = "Back"
const NEXT_TEXT = "Next"
const FINISH_TEXT = "Finish"
const RESET_TEXT = "Reset"
const LAST_STEP_DEFAULT_MESSAGE = "All steps completed - you're finished"

function VerticalStepper({
	steps,
	stepContents,
	orientation = "vertical",
	onFinish,
	disableFinishCondition
}) {
	const classes = useStyles()
	const [activeStep, setActiveStep] = useState(0)

	const handleNext = () => setActiveStep(prev => prev + 1)
	const handleBack = () => setActiveStep(prev => prev - 1)
	const handleReset = () => setActiveStep(0)

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep} orientation={orientation} className={classes.stepper}>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
						<StepContent>
							{stepContents[index]}
							<div className={classes.actionsContainer}>
								<div>
									<Button
										variant={BUTTONS.VARIANTS.OUTLINED}
										color={BUTTONS.COLORS.PRIMARY}
										className="mr-2"
										disabled={activeStep === 0}
										onClick={handleBack}
										text={BACK_TEXT}
									/>

									<Button
										variant={BUTTONS.VARIANTS.CONTAINED}
										color={BUTTONS.COLORS.PRIMARY}
										onClick={activeStep === steps.length - 1 ? onFinish : handleNext}
										disabled={disableFinishCondition}
										text={activeStep === steps.length - 1 ? FINISH_TEXT : NEXT_TEXT}
									/>
								</div>
							</div>
						</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} className={classes.resetContainer}>
					<Typography>{LAST_STEP_DEFAULT_MESSAGE}</Typography>
					<Button onClick={handleReset}>{RESET_TEXT}</Button>
				</Paper>
			)}
		</div>
	)
}

export default VerticalStepper
