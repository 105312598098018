import React from "react"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import ClearIcon from "@mui/icons-material/Clear"

import { HISTORY } from "../_enums/history"

export const TimePicker = ({ label, value, onChange, error, helperText, onClick, ...props }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} data-testid="custom-time-picker">
			<MuiTimePicker
				label={label}
				value={value}
				onChange={onChange}
				renderInput={params => (
					<TextField
						{...params}
						value={value || ""}
						fullWidth
						error={!!error}
						helperText={helperText}
						InputProps={{
							...params.InputProps,
							inputMode: "none",
							endAdornment: (
								<InputAdornment position="end">
									{value && (
										<IconButton
											onClick={() => onClick(null)}
											size={HISTORY.ICON_BUTTONS.SIZE_SMALL}
											edge={HISTORY.ICON_BUTTONS.EDGE_END}
											aria-label={HISTORY.TEXTS.LABELS.CLEAR_TIME}
										>
											<ClearIcon />
										</IconButton>
									)}
									{params.InputProps.endAdornment}
								</InputAdornment>
							)
						}}
					/>
				)}
				{...props}
			/>
		</LocalizationProvider>
	)
}
