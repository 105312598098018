import React, { useEffect } from "react"
import { NewGroupModalTabs } from "./NewGroup/NewGroupModalTabs"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import * as actions from "../_redux/manageGroups/manageGroupsActions"
import * as manageSitesActions from "../_redux/manageSites/manageSitesActions"
import { ERRORS } from "../../_enums/errors"

export function CommunicationCard({ onHide }) {
	const dispatch = useDispatch()

	const { currentCustomer, selectedSite, allGroups, allPeople, allVendors, user } = useSelector(
		state => ({
			currentCustomer: state?.profile?.currentCustomer,
			selectedSite: state?.profile?.currentSite,
			allGroups: state.manageGroups.allGroups,
			allPeople: state.manageGroups.allPeople,
			allvendors: state.profile?.currentCustomer?.vendors || [],
			user: state.auth?.user
		}),
		shallowEqual
	)

	useEffect(() => {
		if (!selectedSite || !currentCustomer) {
			dispatch({
				type: ERRORS.TYPES.SNACKBAR_WARNING,
				payload: { message: ERRORS.SNACKBAR_ERRORS.INVALID_LOCATION }
			})
			return
		}

		dispatch(
			manageSitesActions.fetchSites({
				customerId: currentCustomer.id
			})
		)

		dispatch(
			actions.fetchAllGroups({
				customerId: currentCustomer.id,
				userId: user.id
			})
		)

		if (allPeople && allPeople.length > 0) return
		dispatch(
			actions.fetchCustomerPeople({
				customerId: currentCustomer.id
			})
		)
	}, [currentCustomer, selectedSite, user])

	function hide() {
		onHide()
	}

	return (
		<div data-testid="communication-card">
			<NewGroupModalTabs
				onHide={hide}
				allGroups={allGroups}
				allVendors={allVendors}
				allPeople={allPeople}
			/>
		</div>
	)
}

export default CommunicationCard
