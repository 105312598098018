import React from "react"
import { addDoc, collection, serverTimestamp } from "firebase/firestore"
import { firestore } from "../../../../firebase"
import { ERRORS } from "../../../_enums/errors"

const TWILIO_COLLECTIOMN = "TwilioMessages"
const SUCCESSS_MESSAGE = "Message sent successfully"

export async function sendViaSMS({
	people,
	inputMessage,
	customerId,
	customerName,
	siteId,
	siteName,
	user,
	setSuccess,
	setFailure,
	dispatch
}) {
	const phoneNumbers = people.map(val => val.personalData?.phoneNumber).filter(val => val)
	const promises = phoneNumbers.map(async phoneNumber => {
		const docData = {
			from: process.env.REACT_APP_TWILLIO_FROM_NUMBER,
			to: phoneNumber,
			body: inputMessage,
			senderInformation: {
				customerId,
				customerName,
				siteId,
				siteName: siteName || null,
				userId: user.id,
				userEmail: user.email,
				date: serverTimestamp()
			}
		}

		try {
			await addDoc(collection(firestore, TWILIO_COLLECTIOMN), docData)
			return { success: true }
		} catch (error) {
			return { success: false, message: error.message }
		}
	})

	const results = await Promise.all(promises)

	const successCount = results.filter(res => res.success).length
	const failureCount = results.length - successCount

	if (successCount > 0) {
		setSuccess(true)
		dispatch({
			type: ERRORS.TYPES.SNACKBAR_SUCCESS,
			payload: {
				message: SUCCESSS_MESSAGE
			}
		})
	}

	if (failureCount > 0) {
		setFailure(true)
		dispatch({
			type: ERRORS.TYPES.SNACKBAR_ERROR,
			payload: { message: ERRORS.GENERIC_ERRORS.FAILED_API }
		})
	}
}
