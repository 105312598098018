import React, { useState, useEffect } from "react"
import { Button as MuiButton } from "@mui/material"
import { makeStyles } from "@material-ui/core"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { FormControl } from "../../FormControl"
import { Popover } from "../../Popover"
import { RenderMainPopover } from "./_utils/RenderMainPopover"
import { RenderSubOptionsPopover } from "./_utils/RenderSubOptionsPopover"
import { BUTTONS } from "../../../_enums/buttons"

const DISPLAY_ITEMS_LIMIT = 5
const DISPLAY_ITEMS_DEFAULT_LABEL = "Groups..."

const useStyles = makeStyles(theme => ({
	groupsDropdown: {
		height: "45px",
		width: "100%",
		background: "#F2F2F2 !important",
		alignItems: "center !important",
		borderRadius: "4px !important",
		marginBottom: "0 !important",
		boxShadow: "none !important",
		textTransform: "capitalize !important",
		justifyContent: "space-between !important",
		color: "#4A4A68 !important",
		fontFamily: "Poppins !important",
		fontSize: "12px !important",
		fontWeight: "500 !important",
		"&:hover .MuiButton-root": {
			borderColor: "#8C8CA1 !important",
			borderRadius: "4px !important"
		}
	},

	formControl: {
		width: "100%",
		marginTop: "2px"
	}
}))

export default function NestedOptionsDropdown({
	selectedValues,
	setSelectedValues,
	options,
	hasSearchBox = true,
	setIsClosed,
	...props
}) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [subOptions, setSubOptions] = useState([])
	const [searchTerm, setSearchTerm] = useState("")
	const [groupsCounter, setGroupsCounter] = useState(0)
	const [allGroupsSelected, setAllGroupsSelected] = useState(false)
	const [isMainPopover, setIsMainPopover] = useState(true)
	const [currentGroupLabel, setCurrentGroupLabel] = useState("")

	const classes = useStyles()

	const open = Boolean(anchorEl)

	useEffect(() => {
		if (selectedValues?.length === 0 || options?.length === 0) return

		const fullySelectedGroupsCount = options.reduce((count, group) => {
			const groupItems = group.subOptions?.map(option => option.name) || []
			const isGroupFullySelected =
				groupItems.length > 0 &&
				groupItems.every(item => selectedValues[group.label]?.includes(item))
			return count + (isGroupFullySelected ? 1 : 0)
		}, 0)
		setGroupsCounter(fullySelectedGroupsCount)
		setAllGroupsSelected(
			options.every(group => {
				const groupItems = group.subOptions?.map(option => option.name) || []
				return groupItems.every(item => selectedValues[group.label]?.includes(item))
			})
		)
	}, [selectedValues, options])

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setIsClosed(false)
		setIsMainPopover(true)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setIsClosed(true)
	}

	const isSelected = (group, value) =>
		!!(selectedValues[group] && selectedValues[group].includes(value))

	const getSelectedValuesDisplay = () => {
		const allSelectedValues = Object.values(selectedValues).flat()

		const displayLimit = DISPLAY_ITEMS_LIMIT

		const displayedValues = allSelectedValues.slice(0, displayLimit)
		const displayString = displayedValues.join(", ")

		return allSelectedValues.length > displayLimit
			? `${displayString} ...`
			: displayString || DISPLAY_ITEMS_DEFAULT_LABEL
	}

	return (
		<FormControl className={classes.formControl}>
			<div {...props}>
				<MuiButton
					variant={BUTTONS.VARIANTS.CONTAINED}
					className={classes.groupsDropdown}
					onClick={handleClick}
					endIcon={<KeyboardArrowDownIcon />}
				>
					{getSelectedValuesDisplay()}
				</MuiButton>
				<Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
					{isMainPopover
						? RenderMainPopover({
								options,
								selectedValues,
								setSelectedValues,
								allGroupsSelected,
								setAllGroupsSelected,
								setSubOptions,
								setCurrentGroupLabel,
								setIsMainPopover,
								isSelected,
								groupsCounter,
								searchTerm,
								setSearchTerm,
								hasSearchBox
						  })
						: RenderSubOptionsPopover({
								setIsMainPopover,
								isSelected,
								searchTerm,
								setSearchTerm,
								subOptions,
								currentGroupLabel,
								selectedValues,
								setSelectedValues
						  })}
				</Popover>
			</div>
		</FormControl>
	)
}
