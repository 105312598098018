import React from "react"
import { makeStyles } from "@material-ui/core"
import { Grid, TextField, IconButton } from "@mui/material"

const useStyles = makeStyles(theme => ({
	headerTitle: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontWeight: "500",
		lineHeight: "24px",
		marginRight: theme.spacing(2)
	},
	iconButton: {
		marginRight: theme.spacing(1),
		padding: 0
	},
	searchBox: {
		marginBottom: theme.spacing(2)
	}
}))

export const Header = ({
	title,
	icon: Icon,
	subtitle,
	searchTerm,
	setSearchTerm,
	onBackClick,
	onClose,
	hasSearchBox,
	showIcon = true
}) => {
	const classes = useStyles()

	return (
		<Grid container alignItems="center" justifyContent="space-between" spacing={1}>
			<Grid
				item
				xs={7}
				container
				alignItems="center"
				style={{ paddingTop: showIcon ? "8px" : "4.5px" }}
			>
				{Icon && showIcon && (
					<IconButton
						edge="start"
						aria-label="icon"
						onClick={onBackClick || onClose}
						className={classes.iconButton}
					>
						<Icon />
					</IconButton>
				)}
				<span className={classes.headerTitle}>{title}</span>
				{subtitle && (
					<span
						style={{
							color: "#8C8C8A",
							fontFamily: "Poppins",
							fontSize: "12px",
							fontStyle: "normal",
							fontWeight: "500",
							lineHeight: "18px"
						}}
					>
						{subtitle}
					</span>
				)}
			</Grid>
			{searchTerm !== undefined && hasSearchBox && (
				<Grid item className={classes.searchBox} xs={5}>
					<TextField
						variant="outlined"
						size="small"
						placeholder="Search..."
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
					/>
				</Grid>
			)}
		</Grid>
	)
}
