import React from "react"
import "./Header.css"
import { RenderImgFileAsSvg } from "../../../_partials/RenderImgFileAsSvg"

const OPERATIONS = "Operations"
const OPERATIONS_ICON = "/media/svg/icons/General/operations-icon.svg"

export function Header() {
	return (
		<div style={{ marginBottom: "30px" }}>
			<div className="row justify-content-center">
				<div className="col d-flex align-items-center">
					<div className="operation-header-content">
						<RenderImgFileAsSvg src={OPERATIONS_ICON} />
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>{OPERATIONS}</b>
					</h2>
				</div>
			</div>
		</div>
	)
}
