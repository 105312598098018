import React, { useEffect, useState, useMemo } from "react"
import { useUIContext } from "../../UIContext"
import { DataGrid } from "../../../../_partials/table/DataGrid"
import { shallowEqual, useSelector } from "react-redux"
import RenderRecipientsTableCounter from "../../_partials/RenderRecipientsTableCounter"

const TABLE_TITLE = "Selected Recipients"

const PreviewPeople = () => {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			selectedValues: UIContext.selectedValues,
			setSelectedValues: UIContext.setSelectedValues,
			isClosed: UIContext.isClosed,
			setIsClosed: UIContext.setIsClosed,
			sitesValue: UIContext.sitesValue,
			setConfirmTabRows: UIContext.setConfirmTabRows
		}
	}, [UIContext])

	const { selectedSite, allVendors, allPeople, allGroups } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			allPeople: state?.manageGroups?.allPeople,
			allGroups: state?.manageGroups?.allGroups,
			allVendors: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	const [peopleToUse, setPeopleToUse] = useState([])
	const [filteredPeopleToUse, setFilteredPeopleToUse] = useState(null)

	const columns = [
		{
			field: "name",
			headerName: "Name",
			hideable: false,
			minWidth: 100,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			renderCell: ({ row }) => <span>{row.personalData?.name || "-"}</span>
		},
		{
			field: "companyId",
			headerName: "Company",
			type: "string",
			minWidth: 100,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			renderCell: ({ row }) => {
				const vendor = allVendors?.find(v => v.id === row.companyId)
				return <span>{vendor?.company || row.companyId}</span>
			}
		},
		{
			field: "uuid",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			headerName: "UUID",
			type: "string",
			flex: 1
		}
	]

	const parsePeopleData = data => {
		if (!data?.length) return []

		return data
			.filter(person => UIProps.sitesValue?.includes(person.siteId))
			.map(person => ({
				...person,
				companyName: allVendors?.find(v => v.id === person.companyId)?.company || ""
			}))
			.sort((a, b) => a.personalData?.name?.localeCompare(b.personalData?.name) || 0)
	}

	const handlePeopleToUse = () => {
		if (!allPeople) return
		const parsedPeople = parsePeopleData(allPeople)

		setPeopleToUse(parsedPeople)
		UIProps.setConfirmTabRows(parsedPeople.map(person => person.id))
		setFilteredPeopleToUse(null)
	}

	useEffect(() => {
		if (!UIProps.sitesValue || !selectedSite || !allPeople) return

		handlePeopleToUse()
	}, [UIProps.sitesValue, selectedSite, allPeople])

	useEffect(() => {
		if (!UIProps.isClosed) return

		if (!UIProps.selectedValues || Object.keys(UIProps.selectedValues).length === 0) {
			handlePeopleToUse()
			return
		}

		const { Roles = [], Vendors = [], Default: groups = [] } = UIProps.selectedValues

		const filteredPeople = peopleToUse.filter(
			person =>
				Roles.some(role => person.role?.includes(role)) ||
				Vendors.some(vendor => person.companyName?.includes(vendor))
		)

		const groupMembers = allGroups
			.filter(group => groups.includes(group.name))
			.flatMap(group => group.members)

		const groupPeople = peopleToUse.filter(person => groupMembers.includes(person.id))

		const uniquePeople = Array.from(
			new Map([...filteredPeople, ...groupPeople].map(p => [p.id, p])).values()
		)

		const sortedFilteredPeople = uniquePeople.sort(
			(a, b) => a.personalData?.name?.localeCompare(b.personalData?.name) || 0
		)

		setFilteredPeopleToUse(sortedFilteredPeople)
		UIProps.setConfirmTabRows(sortedFilteredPeople.map(person => person.id))

		UIProps.setIsClosed(false)
	}, [UIProps.isClosed, UIProps.selectedValues])

	const count = `(${filteredPeopleToUse?.length || peopleToUse.length})`

	return (
		<div data-testid="preview-people-container">
			<RenderRecipientsTableCounter title={TABLE_TITLE} count={count} />
			<DataGrid rows={filteredPeopleToUse || peopleToUse} columns={columns} hideFooter={true} />
		</div>
	)
}

export default PreviewPeople
