import React from "react"
import { Button } from "../../Button"
import { BUTTONS } from "../../../_enums/buttons"
import { GROUP_MODAL_TABS } from "../../../modules/Communication/_enums/groupModalTabs"
import { RenderSvgIcon } from "../../../modules/Communication/_partials/RenderSvgIcon"

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`
	}
}

function renderTabIcon(tabIndex) {
	return (
		<RenderSvgIcon
			iconConfig={{
				width: 30,
				height: 30,
				paths: GROUP_MODAL_TABS.ICONS.FIRST_TAB_ICON.PATHS,
				circle: GROUP_MODAL_TABS.ICONS.FIRST_TAB_ICON.CIRLCE,
				text: String(tabIndex + 1)
			}}
			style={{
				fontSize: GROUP_MODAL_TABS.ICONS.FONT_SIZE
			}}
		/>
	)
}
export const renderTabButton = (label, index, { handleChangeIndex, showTable, indexedLabels }) => (
	<Button
		data-testid="tab-menu-button"
		startIcon={label.icon ? label.icon : indexedLabels ? renderTabIcon(index) : null}
		key={index}
		color={BUTTONS.COLORS.PRIMARY}
		text={label.text}
		onClick={() => handleChangeIndex(index)}
		contentAlignment={label.contentAlignment}
		activeTab={showTable && showTable[index]}
		isTabButton={true}
		{...a11yProps(index)}
	/>
)
