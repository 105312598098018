import React from "react"
import { firestore as db } from "../../../../firebase"
import { collection, query, where, orderBy, getDocs, Timestamp } from "firebase/firestore"
import { HISTORY } from "../_enums/history"

/**
 * Fetches history messages or announcements for a selected customer within a specified date and time range.
 *
 * @async
 * @param {Function} setIsLoading - Function to toggle the loading state.
 * @param {Object} dateInputValue - The selected date input value, containing a `$d` property as a raw Date object.
 * @param {Object} timeInputValue - The selected time input value, containing `start` and `end` `$d` properties as raw Date objects.
 * @param {Object} selectedCustomer - The selected customer object, containing an `id` property.
 * @param {Object} user - The user object, containing an `id` property.
 * @param {Function} dispatch - Function to dispatch actions for state management.
 * @param {boolean} showAnnouncements - Flag indicating whether to fetch announcements or messages.
 * @returns {Promise<Object[]>} A promise that resolves to an array of message or announcement objects.
 *
 * @throws Will dispatch a "SNACKBAR_ERROR" action if an error occurs during the query.
 */

export const useFetchHistory = async (
	setIsLoading,
	dateInputValue,
	timeInputValue,
	selectedCustomer,
	user,
	dispatch,
	showAnnouncements
) => {
	try {
		setIsLoading(true)

		const selectedDate = dateInputValue?.$d // Use the .d property for raw Date object

		// Normalize to the start of the selected date (midnight)
		const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0)) // Set time to 00:00:00
		let startTimestamp = Timestamp.fromDate(startOfDay) // Firestore Timestamp for the start of the date

		// Default to covering the entire day if no time input is provided
		let endTimestamp = Timestamp.fromDate(new Date(selectedDate.setHours(23, 59, 59, 999))) // Default to 23:59:59

		if (timeInputValue.start && timeInputValue.end) {
			const startTime = timeInputValue?.start?.$d // Extract the start time as Date
			const endTime = timeInputValue?.end?.$d // Extract the end time as Date

			// Combine the selected date with the start time (start of the selected date + start time)
			const startTimeStampWithDate = new Date(
				selectedDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0)
			)
			const endTimeStampWithDate = new Date(
				selectedDate.setHours(endTime.getHours(), endTime.getMinutes(), 59, 999)
			)

			// Convert to Firestore Timestamps
			startTimestamp = Timestamp.fromDate(startTimeStampWithDate)
			endTimestamp = Timestamp.fromDate(endTimeStampWithDate)
		}

		const messagesRef = collection(db, `Customers/${selectedCustomer.id}/Messages`)
		let q = query(
			messagesRef,
			where("date", ">=", startTimestamp),
			where("date", "<=", endTimestamp),
			where("type", "==", showAnnouncements ? "announcement" : "message"),
			orderBy("date", "desc")
		)

		if (!showAnnouncements) {
			q = query(q, where("erase", "==", false), where("uidFrom", "==", user.id))
		} else {
			q = query(q, where("user.id", "==", user.id))
		}

		const result = await getDocs(q)

		const data = result.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		if (data.length === 0)
			dispatch({
				type: "SNACKBAR_INFO",
				payload: { message: HISTORY.FIELDS.ERRORS.NO_DATA_AVAILABLE }
			})

		return data
	} catch (error) {
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: { message: error.message }
		})
		return []
	} finally {
		setIsLoading(false)
	}
}
