import React, { useState } from "react"
import { TimePicker } from "../_partials/CustomTimePicker"
import { DatePicker } from "../_partials/CustomDatePicker"
import dayjs from "dayjs"

import { HISTORY } from "../_enums/history"
import { useStyles } from "../_partials/StylesOverride"
import { Button } from "../../../_partials/Button"

const DateFilter = ({
	dateInputValue,
	setDateInputValue,
	timeInputValue,
	setTimeInputValue,
	handleSubmit
}) => {
	const classes = useStyles()

	const [errors, setErrors] = useState({ start: "", end: "" })

	// TODO: Extract validateTime function to _utils, make it more flexible and reuse it in the ScheduleModal component
	const validateTime = (start, end) => {
		let newErrors = { start: "", end: "" }

		if (start && !end) {
			newErrors.end = HISTORY.FIELDS.ERRORS.REQUIRED_END_TIME
		}

		if (start && end && dayjs(start).isAfter(dayjs(end))) {
			newErrors.start = HISTORY.FIELDS.ERRORS.START_AFTER_END
		}

		setErrors(newErrors)
		return Object.values(newErrors).every(error => error === "")
	}

	const handleStartChange = newValue => {
		const newTimeInputValue = { ...timeInputValue, start: newValue }
		setTimeInputValue(newTimeInputValue)
		validateTime(newValue, newTimeInputValue.end)
	}
	const handleEndChange = newValue => {
		const newTimeInputValue = { ...timeInputValue, end: newValue }
		setTimeInputValue(newTimeInputValue)
		validateTime(newTimeInputValue.start, newValue)
	}
	const handleDateChange = newValue => {
		setDateInputValue(newValue)
	}

	return (
		<div className="row" data-testid="history-date-filter">
			<div className="col">
				<div style={{ marginBottom: HISTORY.STYLES.LABELS.MARGIN_BOTTOM }}>
					<span className={classes.inputLabel}>{HISTORY.TEXTS.LABELS.DATE}</span>
				</div>

				<DatePicker value={dateInputValue} onChange={handleDateChange} maxDate={new Date()} />
			</div>

			<div className="col">
				<div className="row">
					<div style={{ marginBottom: HISTORY.STYLES.LABELS.MARGIN_BOTTOM }}>
						<span className={classes.inputLabel}>{HISTORY.TEXTS.LABELS.TIME}</span>
					</div>
				</div>

				<div className="row">
					<div className="col pl-0">
						<TimePicker
							label={HISTORY.TEXTS.LABELS.FROM}
							value={timeInputValue.start}
							onChange={handleStartChange}
							error={errors.start}
							helperText={errors.start}
							onClick={handleStartChange}
						/>
					</div>
					<div className="col">
						<TimePicker
							label={HISTORY.TEXTS.LABELS.TO}
							value={timeInputValue.end}
							onChange={handleEndChange}
							error={errors.end}
							helperText={errors.end}
							onClick={handleEndChange}
						/>
					</div>
				</div>
			</div>

			<div className="col-2 mt-4 d-flex align-items-center justify-content-end">
				<Button
					color={HISTORY.BUTTONS.COLOR_PRIMARY}
					variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
					text={HISTORY.BUTTONS.SUBMIT_TEXT}
					onClick={handleSubmit}
					disabled={!dateInputValue || errors.start || errors.end}
					style={{ width: HISTORY.STYLES.BUTTONS.WIDTH }}
					data-testid="submit-date-button"
				/>
			</div>
		</div>
	)
}

export default DateFilter
