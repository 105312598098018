import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import { TABS } from "../../_enums/tabs"
import { renderTabButton } from "./_utils/renderTabButton"
import { renderTabContent } from "./_utils/renderTabContent"

const useStyles = makeStyles(theme => ({
	root: {
		filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",
		"& .MuiAppBar-colorDefault": {
			backgroundColor: "#FFFFFF",
			boxShadow: "none"
		},

		"& .MuiTabs-indicator": {
			top: "0",
			height: "4px"
		},

		"& .MuiTabs-root": {
			borderRadius: "4px"
		}
	}
}))

export default function TabMenu({
	numberOfTabs,
	tabLabels,
	tabContents,
	showTable,
	setShowTable,
	indexedLabels = false,
	...props
}) {
	const classes = useStyles()
	const theme = useTheme()

	const [value, setValue] = useState(0)

	useEffect(() => {
		if (!showTable) return

		const activeIndex = Object.entries(showTable).find(([_, isActive]) => isActive)?.[0]

		if (activeIndex !== undefined) {
			handleChange(null, parseInt(activeIndex, 10))
		}
	}, [showTable])

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = index => {
		setValue(index)
		setShowTable(prevShowTable =>
			tabLabels.reduce((acc, _, idx) => {
				acc[idx] = idx === index
				return acc
			}, {})
		)
	}

	return (
		<div className={classes.root}>
			<AppBar position={TABS.APP_BAR.POSITION.STATIC} color={TABS.APP_BAR.COLOR.DEFAULT}>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor={TABS.INDICATOR_COLOR.PRIMARY}
					textColor={TABS.TEXT_COLOR.PRIMARY}
					variant={TABS.VARIANT.FULL_WIDTH}
					{...props}
				>
					{tabLabels.map((label, index) =>
						renderTabButton(label, index, { handleChangeIndex, showTable, indexedLabels })
					)}
				</Tabs>
			</AppBar>

			{tabContents.map((content, index) => renderTabContent(content, index, { value, theme }))}
		</div>
	)
}

TabMenu.propTypes = {
	numberOfTabs: PropTypes.number.isRequired,
	tabLabels: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			variant: PropTypes.string,
			color: PropTypes.string
		})
	).isRequired,
	tabContents: PropTypes.arrayOf(PropTypes.node).isRequired
}
