import React from "react"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"

export const DatePicker = ({ value, onChange, ...props }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} data-testid="custom-date-picker">
			<MUIDatePicker
				value={value}
				onChange={onChange}
				renderInput={params => <TextField {...params} fullWidth />}
				componentsProps={{
					actionBar: {
						actions: ["clear"]
					}
				}}
				{...props}
			/>
		</LocalizationProvider>
	)
}
