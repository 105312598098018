import React from "react"
import { RenderImgFileAsSvg } from "../../../../_partials/RenderImgFileAsSvg"
import { RenderHeaderMenuItem } from "../_partials/RenderHeaderMenuItem"
import { ROUTES } from "../../_enums/inventory"
import "../Inventory.css"

const IMVENTORY = "Inventory"
const MODELS = "Models"
const FIRMWARE = "Firmware"
const NODES = "Nodes"
const INVENTORY_HEADER_ICON = "/media/svg/icons/General/inventory-header-icon.svg"
const NODES_HEADER_ICON = "/media/svg/icons/General/Nodes.svg"
const MODELS_HEADER_ICON = "/media/svg/icons/General/Models.svg"
const FIRMWARE_HEADER_ICON = "/media/svg/icons/General/Firmware.svg"

const InventoryHeader = () => {
	return (
		<>
			<div
				className="row align-items-center justify-content-between mb-4 w-100"
				data-testid="InventoryHeader"
			>
				<div className="col d-flex align-items-center">
					<div
						style={{
							width: "26px",
							height: "26px",
							marginRight: "12px",
							marginBottom: "10px"
						}}
					>
						<RenderImgFileAsSvg src={INVENTORY_HEADER_ICON} />
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>{IMVENTORY}</b>
					</h2>
				</div>
				<div className="col-sm d-none d-sm-flex justify-content-end">
					<div className="row">
						<RenderHeaderMenuItem
							route={ROUTES.NODES_ROUTE}
							icon={NODES_HEADER_ICON}
							label={NODES}
						/>
						<RenderHeaderMenuItem
							route={ROUTES.MODELS_ROUTE}
							icon={MODELS_HEADER_ICON}
							label={MODELS}
						/>
						<RenderHeaderMenuItem
							route={ROUTES.FIRMWARES_ROUTE}
							icon={FIRMWARE_HEADER_ICON}
							label={FIRMWARE}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default InventoryHeader
