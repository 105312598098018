import React from "react"
import { Checkbox, ListItem, ListItemText } from "@mui/material"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	menuCheckbox: {
		paddingLeft: theme.spacing(2),
		"& .MuiListItemIcon-root": {
			minWidth: "auto"
		}
	}
}))

const SELECT_ALL_LABEL = "Select All"
const COLOR_PRIMARY = "primary"

export const RenderSubOptions = ({
	currentGroupLabel,
	subOptions,
	selectedValues,
	setSelectedValues,
	searchTerm,
	isSelected
}) => {
	const classes = useStyles()

	const toggleSelectValue = (currentGroupLabel, optionName, subOptions) => {
		const newSelected = { ...selectedValues }

		if (optionName === SELECT_ALL_LABEL) {
			const groupItems = subOptions.map(option => option.name)
			const shouldSelectAll = !groupItems.every(item =>
				newSelected[currentGroupLabel]?.includes(item)
			)

			if (shouldSelectAll) {
				newSelected[currentGroupLabel] = groupItems
			} else {
				delete newSelected[currentGroupLabel]
			}
		} else {
			const isSelected = selectedValues[currentGroupLabel]?.includes(optionName)
			if (isSelected) {
				newSelected[currentGroupLabel] = newSelected[currentGroupLabel].filter(
					item => item !== optionName
				)
				if (newSelected[currentGroupLabel].length === 0) {
					delete newSelected[currentGroupLabel]
				}
			} else {
				newSelected[currentGroupLabel] = newSelected[currentGroupLabel]
					? [...newSelected[currentGroupLabel], optionName]
					: [optionName]
			}
		}

		setSelectedValues(newSelected)
	}

	return (
		<>
			{subOptions
				.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
				.map(option => (
					<ListItem
						key={option.value}
						onClick={() => toggleSelectValue(currentGroupLabel, option.name, subOptions)}
						style={{ paddingLeft: "0", paddingRight: "0" }}
					>
						<Checkbox
							checked={isSelected(currentGroupLabel, option.name)}
							color={COLOR_PRIMARY}
							className={classes.menuCheckbox}
						/>
						<ListItemText primary={option.name} />
					</ListItem>
				))}
		</>
	)
}
