import React, { useState } from "react"
import dayjs from "dayjs"

import { TimePicker } from "./CustomTimePicker"
import { DatePicker } from "./CustomDatePicker"
import { useStyles } from "./StylesOverride"
import { HISTORY } from "../_enums/history"
import { Button } from "../../../_partials/Button"

const ScheduleModal = ({ inputDate, setInputDate, inputTime, setInputTime, setShowSchedule }) => {
	const classes = useStyles()
	const [errors, setErrors] = useState(null)

	const validateTime = time => {
		if (!time?.$d) {
			setErrors(HISTORY.FIELDS.ERRORS.INVALID_TIME_FORMAT)
		}
		return Boolean(errors)
	}

	const handleTimeChange = newValue => {
		setInputTime(newValue)
		validateTime(newValue)
	}

	const handleDateChange = newValue => {
		setInputDate(newValue)
	}

	const isToday = inputDate && dayjs(inputDate).isSame(dayjs(), "day")

	return (
		<>
			<div className="row mt-4" data-testid="custom-date-picker">
				<div className="col ">
					<div style={{ marginBottom: HISTORY.STYLES.LABELS.MARGIN_BOTTOM }}>
						<span className={classes.inputLabel}>{HISTORY.TEXTS.LABELS.DATE}</span>
					</div>

					<DatePicker value={inputDate} onChange={handleDateChange} minDate={new Date()} />
				</div>
			</div>

			<div className="row" data-testid="custom-time-picker">
				<div className="col ">
					<div style={{ marginBottom: HISTORY.STYLES.LABELS.MARGIN_BOTTOM }}>
						<span className={classes.inputLabel}>{HISTORY.TEXTS.LABELS.TIME}</span>
					</div>
					<TimePicker
						label={HISTORY.TEXTS.LABELS.TIME}
						value={inputTime}
						onChange={handleTimeChange}
						error={errors}
						helperText={errors}
						onClick={handleTimeChange}
						disabled={!inputDate}
						minTime={isToday ? dayjs() : undefined}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col mt-4 d-flex justify-content-end">
					<Button
						color={HISTORY.BUTTONS.COLOR_PRIMARY}
						variant={HISTORY.BUTTONS.VARIANT_OUTLINED}
						text={
							inputDate && inputTime
								? HISTORY.BUTTONS.CLEAR_TEXT
								: HISTORY.BUTTONS.CANCEL_TEXT
						}
						onClick={() => {
							setShowSchedule(false)
							setInputTime(null)
							setInputDate(null)
						}}
					/>
					<Button
						data-testid="schedule-modal-save-button"
						color={HISTORY.BUTTONS.COLOR_PRIMARY}
						variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
						text={HISTORY.BUTTONS.SAVE_TEXT}
						onClick={() => {
							setShowSchedule(false)
						}}
						disabled={!inputDate || errors}
						className="ml-2"
					/>
				</div>
			</div>
		</>
	)
}

export default ScheduleModal
