import React, { useState, useEffect } from "react"
import SVG from "react-inlinesvg"
import { useFetchDownloadUrl } from "../_hooks/useFetchDownloadUrl"

const RenderImagePreview = ({
	index,
	imgPath,
	alt = "Default image preview",
	defaultImg = "/media/crowdkeep/person.svg"
}) => {
	const [downloadUrl, setDownloadUrl] = useState(null)

	useEffect(() => {
		if (imgPath) {
			useFetchDownloadUrl(imgPath).then(url => setDownloadUrl(url))
		}
	}, [imgPath])

	return (
		<div
			key={index}
			className="icon-preview"
			style={{
				position: "absolute",
				left: `${index * 15}px`,
				margin: "0 15px",
				maxWidth: "20%",
				textAlign: "center"
			}}
		>
			{downloadUrl ? (
				<img
					src={downloadUrl}
					alt={alt}
					style={{
						width: "100%",
						height: "100%",
						borderRadius: "50%",
						margin: "0 5px"
					}}
				/>
			) : (
				<SVG src={defaultImg} />
			)}
		</div>
	)
}

export default RenderImagePreview
