import React from "react"
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		height: "530px",
		fontFamily: "Poppins",
		fontWeight: 400,
		fontSize: "13px",
		border: "none",
		width: "100%",
		color: "#464e5f",
		"& .MuiDataGrid-root": {
			overflow: "auto"
		},

		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0"
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "inherit !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
		minHeight: "90px !important"
	}
}))

export const DataGrid = ({ rows, columns, density = "standard", ...props }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Box sx={{ width: "100%" }}>
				<MuiDataGrid
					rows={rows}
					columns={columns}
					density={density}
					className={classes.root}
					{...props}
					//the props below seems to be in all data grid calls
					//after all data grids have been refactored is this is still true
					//we can keep them as fixed conditions and delete this comments
					pagination
					disableSelectionOnClick
					disableColumnMenu={true}
					hideFooterPagination
					autoHeight={false}
					sortingOrder={["desc", "asc"]}
				/>
			</Box>
		</div>
	)
}
