import React from "react"
import { Popover as MuiPopover } from "@mui/material"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	popoverContent: {
		padding: theme.spacing(2),
		width: "420px",
		maxHeight: "535px !important"
	}
}))

const DEFAULT_ANCHOR_ORIGIN = {
	vertical: "bottom",
	horizontal: "center"
}
const DEFAULT_TRANSFORM_ORIGIN = {
	vertical: "top",
	horizontal: "center"
}

export const Popover = ({
	children,
	anchorEl,
	onClose,
	open,
	anchorOrigin,
	transformOrigin,
	className,
	...props
}) => {
	const classes = useStyles()

	return (
		<MuiPopover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={anchorOrigin || DEFAULT_ANCHOR_ORIGIN}
			transformOrigin={transformOrigin || DEFAULT_TRANSFORM_ORIGIN}
			className={className}
			classes={{ paper: classes.popoverContent }}
			{...props}
		>
			{children}
		</MuiPopover>
	)
}
