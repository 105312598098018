import React from "react"
import { makeStyles } from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"

const useStyles = makeStyles(theme => ({
	title: {
		color: "#444",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "600",
		lineHeight: "160%",
		textTransform: "capitalize",
		padding: "1rem 0"
	},
	description: {
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	container: {
		padding: "1rem",
		backgroundColor: "#FFFFFF"
	},
	toggleButton: {
		display: "flex !important",
		padding: "10px 20px !important",
		justifyContent: "center !important",
		alignItems: "flex-start !important",
		gap: "10px !important",
		flex: "1 0 0 !important",
		borderRadius: "4px !important",
		border: "none !important",

		fontFamily: "Poppins !important",
		fontSize: "16px !important",
		fontStyle: "normal !important",
		fontWeight: "700 !important",
		lineHeight: "normal !important",
		letterSpacing: "0.64px !important",
		textTransform: "uppercase !important"
	},
	normalPriority: { color: "#53B04F !important", backgroundColor: "#E6F4E6 !important" },
	normalPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#53B04F !important"
	},
	mediumPriority: {
		color: "#F6B952 !important",
		backgroundColor: "#FDF1DC !important",
		margin: "0 10px !important"
	},
	mediumPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#F6B952 !important",
		margin: "0 10px !important"
	},
	criticalPriority: {
		color: "#E65019 !important",
		backgroundColor: "#FBE6DE !important"
	},
	criticalPrioritySelected: {
		color: "#FFFFFF !important",
		backgroundColor: "#E65019 !important"
	}
}))

const PRIORITY_LEVEL_LABEL = "Priority Level"
const COLOR_PRIMARY = "primary"
const NORMAL_VALUE = "normal"
const MEDIUM_VALUE = "medium"
const CRITICAL_VALUE = "critical"

const RenderMessagePriorityGroupButton = ({
	title,
	description,
	headerChildren,
	handleButtonGroupChange,
	alignment
}) => {
	const classes = useStyles()

	const CustomToggleButton = ({ alignment, value }) => {
		const classes = useStyles()
		const isSelected = alignment === value

		return (
			<ToggleButton
				className={`${classes.toggleButton} 
        ${
				value === NORMAL_VALUE
					? isSelected
						? classes.normalPrioritySelected
						: classes.normalPriority
					: ""
			} 
        ${
				value === MEDIUM_VALUE
					? isSelected
						? classes.mediumPrioritySelected
						: classes.mediumPriority
					: ""
			} 
        ${
				value === CRITICAL_VALUE
					? isSelected
						? classes.criticalPrioritySelected
						: classes.criticalPriority
					: ""
			}
      `}
				value={value}
			>
				{value}
			</ToggleButton>
		)
	}

	return (
		<>
			<div className="row m-0 justify-content-between">
				<div className={`${classes.title} col`}>{title}</div>
				{headerChildren && headerChildren}
			</div>
			<div className={classes.description}>{description}</div>

			<div className={classes.label}>{PRIORITY_LEVEL_LABEL}</div>
			<ToggleButtonGroup
				color={COLOR_PRIMARY}
				value={alignment}
				exclusive
				onChange={handleButtonGroupChange}
				aria-label={PRIORITY_LEVEL_LABEL}
				fullWidth
			>
				<CustomToggleButton value={NORMAL_VALUE} alignment={alignment} />
				<CustomToggleButton value={MEDIUM_VALUE} alignment={alignment} />
				<CustomToggleButton value={CRITICAL_VALUE} alignment={alignment} />
			</ToggleButtonGroup>
		</>
	)
}

export default RenderMessagePriorityGroupButton
