import React from "react"

function callManagePeople(params) {
	const { operation, dataType, customerId, siteId, data, user } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		dataType,
		siteId,
		data,
		user: {
			callersUserId: user.id,
			callersTenantId: user.tenantID
		}
	})
	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customers/${customerId}/provisioning/manage-people-and-assets-healthcare/${operation}`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

export function createPerson(queryParams) {
	if (!queryParams?.customerId || !queryParams?.person) return
	return callManagePeople({
		operation: "create",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: [queryParams.person],
		user: queryParams.user
	})
}

export function editPerson(queryParams) {
	if (!queryParams?.customerId || !queryParams?.id || !queryParams?.person || !queryParams?.user)
		return

	return callManagePeople({
		operation: "update",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: [{ ...queryParams.person, docId: queryParams.id, visitor: queryParams.visitor }],
		user: queryParams.user
	})
}

export function editPeopleGeofencing(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.peopleToEdit ||
		!queryParams?.user
	)
		return

	return callManagePeople({
		operation: "update",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.peopleToEdit,
		user: queryParams.user
	})
}

export function editPeopleUuid(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.peopleToEdit ||
		!queryParams?.user
	)
		return

	return callManagePeople({
		operation: "update",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.peopleToEdit,
		user: queryParams.user
	})
}

export async function uploadPeople(queryParams) {
	if (!queryParams?.customerId || !queryParams?.people || !queryParams?.user)
		return Promise.resolve(null)

	return callManagePeople({
		operation: "create",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.people,
		user: queryParams.user
	})
}

export function disablePeople(queryParams) {
	if (
		!queryParams?.customerId ||
		!queryParams?.siteId ||
		!queryParams?.people ||
		!queryParams?.user
	)
		return

	return callManagePeople({
		operation: "disable",
		dataType: "people",
		customerId: queryParams.customerId,
		siteId: queryParams.siteId,
		data: queryParams.people,
		user: queryParams.user
	})
}
