import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"

import { useUIContext } from "./UIContext"
import InventoryHeader from "./InventoryHeader/InventoryHeader"
import { NodesInventoryCard } from "./NodesInventory/NodesInventoryCard"
import { ModelsInventoryCard } from "./ModelsInventory/ModelsInventoryCard"
import { FirmwaresInventoryCard } from "./FirmwareInventory/FirmwaresInventoryCard"
import * as actionsInventory from "../../_redux/admin/inventory/InventoryActions"
import { ROUTES } from "../_enums/inventory"
import { getLastValidRoute } from "../../../../_metronic/_helpers"

const ERROR_FETCHING_INVENTORY_DATA = "Error fetching inventory data:"
const ERROR_FETCHING_TECHNOLOGY_LIST = "Error fetching technology list:"
const WIREPAS = "Wirepas"
const INVENTORY_HEADER = "InventoryHeader"
const NODES_INVENTORY_CARD = "NodesInventoryCard"
const MODELS_INVENTORY_CARD = "ModelsInventoryCard"
const FIRMWARES_INVENTORY_CARD = "FirmwaresInventoryCard"

const InventoryMainCard = ({ history }) => {
	const dispatch = useDispatch()
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			setTechnology: UIContext.setTechnology
		}
	}, [UIContext])

	useEffect(() => {
		history && history.push(`${ROUTES.NODES_ROUTE}`)
	}, [history])

	//? I had to set technology here so that the state updates for Export Dropdown and Inventory Table
	//? With useUIContext was not working
	const [technology, setTechnology] = useState(null)
	const [technologyList, setTechnologyList] = useState(null)
	const currentPath = history?.location?.pathname
	const validRoutes = [ROUTES.NODES_ROUTE, ROUTES.MODELS_ROUTE, ROUTES.FIRMWARES_ROUTE]
	const defaultRoute = ROUTES.NODES_ROUTE
	const lastValidRoute = getLastValidRoute(currentPath, validRoutes, defaultRoute)

	const {
		isSuper,
		nodesInventory,
		modelsInventory,
		firmwaresInventory,
		customers,
		currentCustomer,
		permissions
	} = useSelector(
		state => ({
			isSuper: state.auth.claims.isSuper,
			currentCustomer: state.profile?.currentCustomer,
			nodesInventory: state.inventory.nodesInventory,
			modelsInventory: state.inventory.modelsInventory,
			firmwaresInventory: state.inventory.firmwaresInventory,
			customers: state.inventory.customers,
			permissions: state.auth?.permissions?.permissions
		}),
		shallowEqual
	)

	useEffect(() => {
		let isMounted = true

		if (!isSuper) return

		const fetchTechnologyList = async () => {
			try {
				const result = await dispatch(actionsInventory.getTechnologyList())
				if (isMounted && result && result.includes(WIREPAS)) {
					setTechnologyList(result)
					setTechnology(WIREPAS)
				}
			} catch (error) {
				if (isMounted) {
					console.error(ERROR_FETCHING_TECHNOLOGY_LIST, error)
				}
			}
		}

		fetchTechnologyList()

		return () => {
			isMounted = false
		}
	}, [isSuper, dispatch])

	useEffect(() => {
		if (!technology) return

		const fetchData = async () => {
			try {
				//if these API's don't have a customerId param, it will return all nodes from all customers
				const customerId = isSuper ? null : currentCustomer.id

				await Promise.all([
					dispatch(
						actionsInventory.getNodes({
							technology,
							customerId: customerId
						})
					),
					dispatch(actionsInventory.getModels({ technology, customerId: customerId })),
					dispatch(actionsInventory.getFirmwares({ technology, customerId: customerId })),
					dispatch(actionsInventory.getCustomersAndSites())
				])
			} catch (error) {
				console.error(ERROR_FETCHING_INVENTORY_DATA, error)
			}
		}

		fetchData()
	}, [technology, currentCustomer?.id, dispatch])

	const handleTechnology = (event, newValue) => {
		setTechnology(newValue)
	}

	useEffect(() => {
		if (!technology) return
		UIProps.setTechnology(technology)
	}, [technology])

	return (
		<>
			<InventoryHeader data-testid={INVENTORY_HEADER} />
			{permissions?.Operations?.Inventory?.Nodes && lastValidRoute === ROUTES.NODES_ROUTE && (
				<NodesInventoryCard
					nodesInventory={nodesInventory}
					technology={technology}
					setTechnology={setTechnology}
					handleTechnology={handleTechnology}
					technologyList={technologyList}
					allCustomers={customers}
					firmwaresInventory={firmwaresInventory}
					data-testid={NODES_INVENTORY_CARD}
				/>
			)}
			{permissions?.Operations?.Inventory?.Models && lastValidRoute === ROUTES.MODELS_ROUTE && (
				<ModelsInventoryCard
					modelsInventory={modelsInventory}
					technology={technology}
					setTechnology={setTechnology}
					handleTechnology={handleTechnology}
					technologyList={technologyList}
					data-testid={MODELS_INVENTORY_CARD}
				/>
			)}
			{permissions?.Operations?.Inventory?.Firmwares &&
				lastValidRoute === ROUTES.FIRMWARES_ROUTE && (
					<FirmwaresInventoryCard
						firmwaresInventory={firmwaresInventory}
						technology={technology}
						setTechnology={setTechnology}
						handleTechnology={handleTechnology}
						technologyList={technologyList}
						data-testid={FIRMWARES_INVENTORY_CARD}
					/>
				)}
		</>
	)
}

export default InventoryMainCard
