export const TABS = {
	APP_BAR: {
		POSITION: {
			STATIC: "static"
		},
		COLOR: {
			DEFAULT: "default"
		}
	},
	INDICATOR_COLOR: {
		PRIMARY: "primary"
	},
	TEXT_COLOR: {
		PRIMARY: "primary"
	},
	VARIANT: {
		FULL_WIDTH: "fullWidth"
	}
}
