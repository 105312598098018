import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../_metronic/_helpers"

/**
 * Utility function to render an SVG icon with support for multiple paths
 * @param {Object} props - Props passed to the RenderImgFileAsSvg component
 * @param {string} props.src - Path or URL to the SVG file
 * @param {Object} [props.style] - Optional custom styles for the SVG
 * @param {string} [props.className] - Optional className for additional CSS styling
 * @param {string} [props.fill] - Optional dynamic fill color for the SVG icon
 * @returns {JSX.Element}
 */
export function RenderImgFileAsSvg({ src, style, className, fill, ...props }) {
	return (
		<SVG
			{...props}
			className={className}
			style={{
				...style,
				height: "2rem",
				width: "2rem",
				fill: fill || "#3077d3"
			}}
			src={toAbsoluteUrl(src)}
			aria-label={props?.alt || "SVG icon"}
		/>
	)
}
