import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import RenderImagePreview from "./RenderImagePreview"

const SELECTED_LABEL = "Selected"
const IMG_ALT = "site profile image"

const SelectedItemsCounter = ({ selectedItems, allItems, label = "Items" }) => {
	return (
		<div style={{ marginTop: "10px", marginBottom: "10px" }} className="row">
			{selectedItems && selectedItems.length > 0 && (
				<div className="col-8">
					<div className="row" style={{ paddingLeft: "12.5px" }}>
						<div
							className="col-5 d-flex"
							style={{ position: "relative", overflow: "hidden", height: "31px" }}
						>
							{selectedItems.slice(0, 6).map((data, index) => {
								const site = allItems?.find(aSite => aSite.id === data)
								const imgPath = site?.siteInfo?.imagePaths?.[0]

								return <RenderImagePreview index={index} imgPath={imgPath} alt={IMG_ALT} />
							})}
						</div>
						<div
							className="col-1 select-counter"
							style={{ fontWeight: 700, alignContent: "center" }}
						>
							{selectedItems?.length}
						</div>
						<div className="col select-counter" style={{ alignContent: "center" }}>
							{label} {SELECTED_LABEL}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

SelectedItemsCounter.propTypes = {
	selectedItems: PropTypes.array.isRequired,
	allItems: PropTypes.array.isRequired
}

export default SelectedItemsCounter
