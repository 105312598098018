export const BUTTONS = {
	COLORS: {
		PRIMARY: "primary",
		PRIMARY_LIGHT: "primaryLight",
		SECONDARY: "secondary",
		SECONDARY_LIGHT: "secondaryLight",
		WARNING: "warning",
		WARNING_LIGHT: "warningLight",
		ERROR: "error",
		ERROR_LIGHT: "errorLight",
		SUCCESS: "success",
		SUCCESS_LIGHT: "successLight",
		GREY_LIGHT: "greyLight"
	},
	VARIANTS: {
		CONTAINED: "contained",
		OUTLINED: "outlined"
	}
}
