import React, { useEffect, useMemo, useState } from "react"
import SelectPeopleTab from "./SelectPeopleTab/SelectPeopleTab"
import ConfirmTab from "./ConfirmTab.js/ConfirmTab"
import SendMessage from "./SendMessage/SendMessage"
import { useUIContext } from "../UIContext"
import TabMenu from "../../../_partials/tabs/TabMenu"
import { GROUP_MODAL_TABS } from "../_enums/groupModalTabs"

export function NewGroupModalTabs({
	onHide,
	initialValues,
	groupId,
	allGroups,
	allVendors,
	allPeople
}) {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			setSitesValue: UIContext.setSitesValue,
			setGroupName: UIContext.setGroupName,
			setSelectedValues: UIContext.setSelectedValues,
			setGroupId: UIContext.setGroupId,
			members: UIContext.members,
			setMembers: UIContext.setMembers
		}
	}, [UIContext])

	useEffect(() => {
		if (!groupId || !initialValues) return

		UIProps.setGroupName(initialValues?.name)
		UIProps.setGroupId(groupId)
		UIProps.setMembers(initialValues?.members)

		const selectedGroup = allGroups?.find(group => group.id === groupId)
		if (selectedGroup && selectedGroup.sites?.length > 0) {
			UIProps.setSitesValue(selectedGroup.sites)
		}
	}, [groupId, initialValues, allGroups])

	useEffect(() => {
		if (!UIProps.members) return

		const people =
			allPeople &&
			allPeople.filter(person => {
				return UIProps.members.includes(person.id)
			})

		if (people && people.length > 0) {
			const selectedValues = { Roles: new Set(), Vendors: new Set() }
			people.forEach(person => {
				if (person.role) selectedValues.Roles.add(person.role)
				if (person.companyId) {
					const vendor = allVendors.find(vendor => vendor.id === person.companyId)
					if (vendor) selectedValues.Vendors.add(vendor.company)
				}
			})

			UIProps.setSelectedValues({
				Roles: Array.from(selectedValues.Roles),
				Vendors: Array.from(selectedValues.Vendors)
			})
		}
	}, [UIProps.members])

	const [showTable, setShowTable] = useState([true, false, false])

	const handleChange = (event, newValue) => {
		setShowTable(showTable.map((_, index) => index === newValue))
	}

	const tabLabels = useMemo(
		() => [
			{
				text: GROUP_MODAL_TABS.TEXTS.TITLES.SELECT_SITES_AND_GROUPS,
				contentAlignment: GROUP_MODAL_TABS.STYLES.CONTENT_ALIGNMENT_CENTER
			},
			{
				text: GROUP_MODAL_TABS.TEXTS.TITLES.CONFIRM_RECIPIENTS,
				contentAlignment: GROUP_MODAL_TABS.STYLES.CONTENT_ALIGNMENT_CENTER
			},
			{
				text: GROUP_MODAL_TABS.TEXTS.TITLES.CONFIRM_RECIPIENTS,
				contentAlignment: GROUP_MODAL_TABS.STYLES.CONTENT_ALIGNMENT_CENTER
			}
		],
		[]
	)

	const tabContents = useMemo(
		() => [
			<div style={{ width: "99%" }}>
				<SelectPeopleTab
					handleChange={handleChange}
					allGroups={allGroups}
					allPeople={allPeople}
				/>
			</div>,
			<div>
				<ConfirmTab onHide={onHide} handleChange={handleChange} />
			</div>,
			<div>
				<SendMessage onHide={onHide} handleChange={handleChange} />
			</div>
		],
		[allGroups, allPeople, onHide]
	)

	return (
		<TabMenu
			data-testid="manage-site-tab-menu"
			numberOfTabs={tabLabels.length}
			tabLabels={tabLabels}
			indexedLabels
			tabContents={tabContents}
			showTable={showTable}
			setShowTable={setShowTable}
			disableHeaderClick
		/>
	)
}
