import React, { useState, useRef } from "react"
import { VariableSizeList } from "react-window"
import { MessagePriorityLabel, MessageRecipientsLabel } from "./RenderStyledMessageLabel"
import { HISTORY } from "../_enums/history"
import { useStyles } from "../_partials/StylesOverride"
import { Button } from "../../../_partials/Button"

const HistoryList = ({ histories, handleResend, isResending, showAnnouncements }) => {
	const classes = useStyles()
	const listRef = useRef()

	const [expandedIndex, setExpandedIndex] = useState(null)

	const getItemSize = index =>
		expandedIndex === index
			? HISTORY.STYLES.LISTS.VARIABLE_SIZE_LIST.EXPANDED_ITEM_SIZE
			: HISTORY.STYLES.LISTS.VARIABLE_SIZE_LIST.COLLAPSED_ITEM_SIZE
	const toggleExpand = index => {
		setExpandedIndex(prev => (prev === index ? null : index))

		// Reset the size after the index to ensure proper rendering
		listRef.current.resetAfterIndex(index)
	}

	return (
		<VariableSizeList
			ref={listRef}
			height={HISTORY.STYLES.LISTS.VARIABLE_SIZE_LIST.HEIGHT}
			width={HISTORY.STYLES.LISTS.VARIABLE_SIZE_LIST.WIDTH}
			itemSize={getItemSize}
			itemCount={histories.length}
			overscanCount={5}
		>
			{({ index, style }) => {
				const history = histories[index]
				const { id, subject, body, date, critical, to } = history

				let messageTimestamp = date instanceof Date ? date : date?.toDate?.()

				const isExpanded = expandedIndex === index

				return (
					<div
						style={{
							...style,
							backgroundColor: isExpanded
								? HISTORY.STYLES.LISTS.DIVS.BACKGROUND_COLOR_EXPANDED
								: HISTORY.STYLES.LISTS.DIVS.BACKGROUND_COLOR_COLLAPSED
						}}
						className={`row ${classes.historyMainDivList}`}
						key={index}
					>
						<div
							className="row pr-0"
							style={{
								width: HISTORY.STYLES.LISTS.ROWS.WIDTH,
								padding: HISTORY.STYLES.LISTS.ROWS.PADDING
							}}
						>
							<div className="col d-flex p-0">
								<MessagePriorityLabel critical={critical} />
								{!showAnnouncements ? <MessageRecipientsLabel recipient={to} /> : null}
							</div>
							<div
								className="col d-flex justify-content-end align-items-start pr-0"
								style={{ textAlign: HISTORY.STYLES.LISTS.COLS.TEXT_ALIGN }}
							>
								<span
									className={classes.historyHeaderInfo}
									style={{
										color: HISTORY.STYLES.LISTS.SPANS.TIMESTAMP.COLOR,
										margin: HISTORY.STYLES.LISTS.SPANS.TIMESTAMP.MARGIN,
										paddingRight: HISTORY.STYLES.LISTS.SPANS.TIMESTAMP.PADDING_RIGHT,
										paddingTop: HISTORY.STYLES.LISTS.SPANS.TIMESTAMP.PADDING_TOP
									}}
								>
									{messageTimestamp?.toLocaleString()}
								</span>
							</div>
						</div>

						<div className="row w-100">
							<div className="col-10">
								<div
									className="row"
									style={{
										width: HISTORY.STYLES.LISTS.ROWS.WIDTH,
										padding: HISTORY.STYLES.LISTS.ROWS.PADDING
									}}
								>
									<b>{HISTORY.TEXTS.LABELS.SUBJECT}&nbsp;</b> {subject}
								</div>
								<div
									className={`row ${classes.historyMessageDiv}`}
									style={{
										whiteSpace: isExpanded
											? HISTORY.STYLES.LISTS.DIVS.MESSAGE_DIV.WHITE_SPACE_NORMAL
											: HISTORY.STYLES.LISTS.DIVS.MESSAGE_DIV.WHITE_SPACE_NOWRAP,
										overflow: isExpanded
											? HISTORY.STYLES.LISTS.DIVS.MESSAGE_DIV.OVERFLOW_VISIBLE
											: HISTORY.STYLES.LISTS.DIVS.MESSAGE_DIV.OVERFLOW_HIDDEN,
										textOverflow: isExpanded
											? HISTORY.STYLES.LISTS.DIVS.MESSAGE_DIV.TEXT_OVERFLOW_CLIP
											: HISTORY.STYLES.LISTS.DIVS.MESSAGE_DIV.TEXT_OVERFLOW_ELLIPSIS
									}}
								>
									<b>{HISTORY.TEXTS.LABELS.MESSAGE}&nbsp;</b> {body}
								</div>
							</div>
							<div
								className="col align-self-end pr-0"
								style={{ textAlign: HISTORY.STYLES.LISTS.COLS.TEXT_ALIGN }}
							>
								<Button
									className="mr-2"
									color={HISTORY.BUTTONS.COLOR_PRIMARY_LIGHT}
									variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
									text={
										isExpanded ? HISTORY.BUTTONS.CLOSE_TEXT : HISTORY.BUTTONS.OPEN_TEXT
									}
									onClick={() => toggleExpand(index)}
								/>
								<Button
									color={HISTORY.BUTTONS.COLOR_PRIMARY}
									variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
									disabled={isResending}
									text={HISTORY.BUTTONS.RESEND_TEXT}
									onClick={() => {
										handleResend(id)
									}}
								/>
							</div>
						</div>
					</div>
				)
			}}
		</VariableSizeList>
	)
}

export default HistoryList
