import React, { useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { UIProvider } from "./UIContext"
import CommunicationCard from "./CommunicationCard"
import AnnouncementsPage from "./Announcements/AnnouncementsPage"
import { RenderSvgIcon } from "./_partials/RenderSvgIcon"
import HistoryCard from "./History/HistoryCard"
import { HISTORY } from "./_enums/history"
import { Button } from "../../_partials/Button"

export const CommunicationPage = () => {
	const UIEvents = {}

	const { permissions, selectedCustomer, user } = useSelector(
		state => ({
			permissions: state.auth?.permissions?.permissions,
			selectedCustomer: state.profile?.currentCustomer,
			user: state.auth?.user
		}),
		shallowEqual
	)

	const [showAnnouncements, setShowAnnouncements] = useState(false)
	const [showHistory, setShowHistory] = useState(false)

	return (
		<UIProvider UIEvents={UIEvents}>
			<div
				className="row align-items-sm-end align-items-center p-0"
				style={{ minHeight: "5rem", marginBottom: "1rem" }}
				data-testid="communication-page"
			>
				<div className="d-flex align-items-center col">
					<RenderSvgIcon
						iconConfig={{
							paths: HISTORY.STYLES.ICONS.COMMUNICATION_ICON.PATHS,
							fill: HISTORY.STYLES.ICONS.PRIMARY_FILL
						}}
						style={{
							fontSize: HISTORY.STYLES.ICONS.FONT_SIZE,
							marginRight: HISTORY.STYLES.ICONS.MARGIN_RIGHT
						}}
					/>

					<h2 style={{ color: HISTORY.STYLES.HEADER.COLOR }}>
						<b>{HISTORY.TEXTS.TITLES.COMMUNICATION_TITLE}</b>
					</h2>
				</div>
				<div
					className="d-flex align-items-center col"
					style={{
						placeContent: "flex-end"
					}}
				>
					<Button
						color={HISTORY.BUTTONS.COLOR_PRIMARY}
						variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
						disabled={permissions && !permissions.Announcements}
						text={
							showAnnouncements
								? HISTORY.BUTTONS.MESSAGES_TEXT
								: HISTORY.BUTTONS.ANNOUNCEMENTS_TEXT
						}
						onClick={() => setShowAnnouncements(prev => !prev)}
					/>
					<Button
						className="ml-2"
						color={
							!showHistory
								? HISTORY.BUTTONS.COLOR_GREY_LIGHT
								: HISTORY.BUTTONS.COLOR_PRIMARY_LIGHT
						}
						style={{ width: HISTORY.STYLES.BUTTONS.SHOW_HISTORY_PAGE.WIDTH }}
						variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
						startIcon={
							<RenderSvgIcon
								iconConfig={{
									paths: HISTORY.STYLES.ICONS.HISTORY_ICON.PATHS,
									fill: !showHistory
										? HISTORY.STYLES.ICONS.DISABLED_FILL
										: HISTORY.STYLES.ICONS.PRIMARY_FILL
								}}
								style={{
									margin: HISTORY.STYLES.ICONS.MARGIN
								}}
							/>
						}
						onClick={() => setShowHistory(prev => !prev)}
					/>
				</div>
			</div>
			{!showAnnouncements && !showHistory && (
				<CommunicationCard data-testid="communication-card" />
			)}
			{showAnnouncements && permissions && permissions.Announcements && !showHistory && (
				<AnnouncementsPage />
			)}

			{showHistory && (
				<HistoryCard
					data-testid="history-card"
					showAnnouncements={showAnnouncements}
					selectedCustomer={selectedCustomer}
					user={user}
				/>
			)}
		</UIProvider>
	)
}
