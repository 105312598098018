import React from "react"
import { NavLink } from "react-router-dom"
import { useLocation } from "react-router"
import { RenderImgFileAsSvg } from "../../../../_partials/RenderImgFileAsSvg"
import { checkIsActive, getLastValidRoute } from "../../../../../_metronic/_helpers"
import { ROUTES } from "../../_enums/inventory"

export const RenderHeaderMenuItem = ({ route, icon, label }) => {
	const location = useLocation()
	const validRoutes = [ROUTES.NODES_ROUTE, ROUTES.MODELS_ROUTE, ROUTES.FIRMWARES_ROUTE]
	const defaultRoute = ROUTES.NODES_ROUTE
	const lastValidRoute = getLastValidRoute(location.pathname, validRoutes, defaultRoute)

	const getMenuItemActive = url => {
		return checkIsActive(lastValidRoute, url) ? true : false
	}

	return (
		<div
			className="col-sm"
			style={{
				textAlign: "center",
				borderBottom: getMenuItemActive(route) ? "4px solid #2673F0" : "none",
				padding: "10px"
			}}
		>
			<NavLink className="operations-menu-link" to={route}>
				<RenderImgFileAsSvg
					src={icon}
					fill={getMenuItemActive(route) ? "#2673F0" : "#4A4A68"}
					style={{
						marginBottom: "5px"
					}}
				/>
				<br />
				<span
					className="header-menu-text"
					style={{
						color: getMenuItemActive(route) ? "#2673F0" : "#4A4A68"
					}}
				>
					{label}
				</span>
			</NavLink>
		</div>
	)
}
