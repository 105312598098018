import React, { useEffect, useState, useMemo } from "react"
import { useSelector, shallowEqual } from "react-redux"
import NestedOptionsDropdown from "../../../../../_partials/inputs/NestedOptionsDropdown/NestedOptionsDropdown"
import SelectedItemsCounter from "../../../../../_partials/SelectedItemsCounter"
import { useUIContext } from "../../../UIContext"
import { createNestedDropdownOptions } from "../../../_utils/useCreateNestedDropdownOptions"

const GROUPS_LABEL = "Groups"
const ROLES_LABEL = "Roles"
const VENDORS_LABEL = "Vendors"
const DEFAULT_LABEL = "Default"
const COMPANY_LABEL = "company"

const GroupsFilter = () => {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			groupId: UIContext.groupId,
			selectedValues: UIContext.selectedValues,
			setSelectedValues: UIContext.setSelectedValues,
			setIsClosed: UIContext.setIsClosed
		}
	}, [UIContext])

	const { allGroups, allRoles, allVendors } = useSelector(
		state => ({
			allGroups: state?.manageGroups?.allGroups || [],
			allRoles: state.profile?.currentCustomer?.roles || [],
			allVendors: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	const [options, setOptions] = useState([])

	useEffect(() => {
		if (UIProps?.selectedValues?.length === 0) return

		UIProps.setIsClosed(true)
	}, [])

	const computedOptions = useMemo(() => {
		const rolesOptions = allRoles.length
			? createNestedDropdownOptions(ROLES_LABEL, allRoles)
			: null
		const vendorsOptions = allVendors.length
			? createNestedDropdownOptions(VENDORS_LABEL, allVendors, COMPANY_LABEL)
			: null
		const groupsOptions = allGroups.length
			? createNestedDropdownOptions(DEFAULT_LABEL, allGroups)
			: null

		return [rolesOptions, vendorsOptions, groupsOptions].filter(Boolean)
	}, [allRoles, allVendors, allGroups])

	useEffect(() => {
		setOptions(prevOptions =>
			JSON.stringify(prevOptions) === JSON.stringify(computedOptions)
				? prevOptions
				: computedOptions
		)
	}, [computedOptions])

	const selectedItems = Object.values(UIProps.selectedValues).flat()

	return (
		<div data-testid="groups-filter">
			<NestedOptionsDropdown
				selectedValues={UIProps.selectedValues}
				setSelectedValues={UIProps.setSelectedValues}
				options={options}
				hasSearchBox={false}
				setIsClosed={UIProps.setIsClosed}
			/>

			<div>
				<SelectedItemsCounter
					selectedItems={selectedItems}
					allItems={[]}
					label={GROUPS_LABEL}
				/>
			</div>
		</div>
	)
}

export default GroupsFilter
