import React from "react"
import { Input } from "../../../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

const FILLED_VARIENT = "filled"
const SUBJECT_LABEL = "Subject"
const SUBJECT_FIELD = "subject"
const SUBJECT_PLACEHOLDER = "Enter subject"
const MESSAGE_LABEL = "Message"
const MESSAGE_FIELD = "message"
const MESSAGE_PLACEHOLDER = "Enter message"
const AREA_TEXT_ROWS = 6
const REQUIRED_FIELD = "*"

const EditSchema = Yup.object().shape({
	subject: Yup.string().nullable().trim().min(1, "Subject must not be empty"),
	message: Yup.string().nullable().trim().min(1, "Message must not be empty")
})
const initialValues = {
	subject: "",
	message: ""
}

const RenderSendMessageForm = ({
	inputSubject,
	setInputSubject,
	inputMessage,
	setInputMessage,
	classes
}) => {
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			data-testid="NewNodeModel"
		>
			{({ setFieldValue }) => {
				return (
					<Form className="form form-label-right p-3" data-testid="NewNodeModel">
						<div className="form-group row mb-0 mt-2">
							<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
								<div className={classes.label}>
									{SUBJECT_LABEL} <sup style={{ color: "red" }}> {REQUIRED_FIELD}</sup>
								</div>
							</div>
							<div className="col" style={{ padding: "0", marginBottom: "2rem" }}>
								<Field
									variant={FILLED_VARIENT}
									name={SUBJECT_FIELD}
									placeholder={SUBJECT_PLACEHOLDER}
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									value={inputSubject}
									onChange={e => {
										if (!e.target.value) {
											setInputSubject("")
											setFieldValue(SUBJECT_FIELD, "")
										} else {
											setInputSubject(e.target.value)
											setFieldValue(SUBJECT_FIELD, e.target.value)
										}
									}}
								/>
							</div>
						</div>
						<div className="form-group row mb-0">
							<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
								<div className={classes.label}>
									{MESSAGE_LABEL} <sup style={{ color: "red" }}> {REQUIRED_FIELD}</sup>
								</div>
							</div>
							<div className="col" style={{ padding: "0", marginBottom: "2rem" }}>
								<Field
									variant={FILLED_VARIENT}
									name={MESSAGE_FIELD}
									placeholder={MESSAGE_PLACEHOLDER}
									multiline
									rows={AREA_TEXT_ROWS}
									component={Input}
									InputProps={{
										disableUnderline: true,
										style: { height: "auto", padding: "12px" }
									}}
									value={inputMessage}
									onChange={e => {
										if (!e.target.value) {
											setInputMessage("")
											setFieldValue(MESSAGE_FIELD, "")
										} else {
											setInputMessage(e.target.value)
											setFieldValue(MESSAGE_FIELD, e.target.value)
										}
									}}
									data-testid="Formik_Form_Notes"
								/>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default RenderSendMessageForm
