// FilterPeople.js
import React, { useState, useMemo, useEffect } from "react"
import { useUIContext } from "../../UIContext"
import VerticalStepper from "../../../../_partials/VerticalStepper"
import SitesFilter from "./FilterPeopleSteps/SitesFilter"
import GroupsFilter from "./FilterPeopleSteps/GroupsFilter"

const STEPS = ["Sites", "Groups"]

export default function FilterPeople({ handleChange, allPeople }) {
	const UIContext = useUIContext()
	const UIProps = useMemo(
		() => ({
			groupId: UIContext.groupId,
			groupName: UIContext.groupName,
			sitesValue: UIContext.sitesValue
		}),
		[UIContext]
	)

	const [sitesValue, setSitesValue] = useState(UIProps.sitesValue)

	useEffect(() => {
		if (!UIProps.groupId) return
		setSitesValue(UIProps.sitesValue)
	}, [UIProps.groupId])

	const stepContents = [
		<SitesFilter
			sitesValue={sitesValue}
			setSitesValue={setSitesValue}
			groupName={UIProps.groupId}
		/>,
		<GroupsFilter />
	]

	return (
		<VerticalStepper
			steps={STEPS}
			stepContents={stepContents}
			onFinish={e => handleChange(e, 1)}
			disableFinishCondition={
				(STEPS.length - 1 && sitesValue?.length === 0) || allPeople?.length === 0
			}
			data-testid="filter-people-container"
		/>
	)
}
