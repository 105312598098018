import React from "react"
import { Header } from "../NestedOptionsDropdownHeader"
import { List } from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { RenderSubOptions } from "./RenderSubOptions"

export const RenderSubOptionsPopover = ({
	setIsMainPopover,
	isSelected,
	searchTerm,
	setSearchTerm,
	subOptions,
	currentGroupLabel,
	selectedValues,
	setSelectedValues
}) => {
	const checkedCount = subOptions.filter(option =>
		isSelected(currentGroupLabel, option.name)
	).length

	return (
		<div style={{ position: "relative", padding: "8px" }}>
			<Header
				title={currentGroupLabel}
				subtitle={`${checkedCount}/${subOptions.length} selected`}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				onBackClick={() => setIsMainPopover(true)}
				icon={ArrowBackIosIcon}
			/>
			<List>
				{RenderSubOptions({
					currentGroupLabel,
					subOptions,
					selectedValues,
					setSelectedValues,
					searchTerm,
					isSelected
				})}
			</List>
		</div>
	)
}
