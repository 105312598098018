import React from "react"
import { SvgIcon } from "@material-ui/core"

/**
 * Utility function to render an SVG icon with support for multiple paths
 * @param {Object} iconConfig - Configuration for the SVG icon
 * @param {Object} props - Props passed to the SvgIcon component
 * @returns {JSX.Element}
 */
export function RenderSvgIcon({ iconConfig, ...props }) {
	const {
		width = 24,
		height = 24,
		viewBox = `0 0 ${width} ${height}`,
		svgFill = "currentColor",
		fill = "currentColor",
		textColor = "white",
		paths = [],
		circle = null,
		text = ""
	} = iconConfig

	const ALIGN_MIDDLE = "middle"
	const FONT_SIZE = "12px"

	return (
		<SvgIcon {...props} width={width} height={height} viewBox={viewBox} htmlColor={svgFill}>
			{paths.map((path, index) => (
				<>
					<path key={index} d={path} fill={fill} />
				</>
			))}
			{circle && <circle cx={circle.cx} cy={circle.cy} r={circle.r} />}
			{text && (
				<text
					x={circle?.cx}
					y={circle?.cy}
					textAnchor={ALIGN_MIDDLE}
					alignmentBaseline={ALIGN_MIDDLE}
					fill={textColor}
					fontSize={FONT_SIZE}
				>
					{text}
				</text>
			)}
		</SvgIcon>
	)
}
