import React from "react"
import { HISTORY } from "../_enums/history"
import { useStyles } from "./StylesOverride"

/**
 * MessagePriorityLabel Component
 *
 * Displays a label indicating the priority of a message.
 * The priority can be one of three levels: critical, medium, or normal.
 *
 * @param {number} props.critical - The priority level (1, 2, or 3).
 *
 * @returns {JSX.Element} - A styled label indicating the priority level of the message.
 */

export const MessagePriorityLabel = ({ critical }) => {
	const classes = useStyles()
	const priorityStyles = {
		3: {
			borderColor: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.CRITICAL_PRIORITY_COLOR,
			color: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.CRITICAL_PRIORITY_COLOR,
			label: HISTORY.TEXTS.LABELS.PRIORITY_CRITICAL
		},
		2: {
			borderColor: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.MEDIUM_PRIORITY_COLOR,
			color: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.MEDIUM_PRIORITY_COLOR,
			label: HISTORY.TEXTS.LABELS.PRIORITY_MEDIUM
		},
		1: {
			borderColor: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.NORMAL_PRIORITY_COLOR,
			color: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.NORMAL_PRIORITY_COLOR,
			label: HISTORY.TEXTS.LABELS.PRIORITY_NORMAL
		}
	}

	const { borderColor, color, label } = priorityStyles[critical || 1]

	return (
		<div
			className={classes.historyHeaderInfo}
			style={{
				border: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.BORDER,
				borderColor,
				color,
				fontWeight: HISTORY.STYLES.MESSAGE_PRIORITY_DIV.FONT_WEIGHT
			}}
		>
			{label}
		</div>
	)
}

/**
 * MessageRecipientsLabel Component
 *
 * Displays a label indicating the recipients of a message.
 * If no recipient is provided, a default "No recipient" label will be shown.
 *
 * @param {string} props.recipient - The recipient(s) of the message.
 *
 * @returns {JSX.Element} - A styled label showing the recipient(s) of the message.
 */
export const MessageRecipientsLabel = ({ recipient }) => {
	const classes = useStyles()

	return (
		<div
			className={classes.historyHeaderInfo}
			style={{
				border: HISTORY.STYLES.MESSAGE_RECIPIENT_DIV.BORDER,
				borderColor: HISTORY.STYLES.MESSAGE_RECIPIENT_DIV.COLOR,
				color: HISTORY.STYLES.MESSAGE_RECIPIENT_DIV.COLOR
			}}
		>
			{HISTORY.TEXTS.LABELS.RECIPIENT}&nbsp;{recipient || HISTORY.TEXTS.LABELS.NO_RECIPIENT}
		</div>
	)
}
