/* eslint-disable no-restricted-imports */
import React from "react"
import { Modal as BootstrapModal } from "react-bootstrap"
import { ModalProgressBar } from "../../_partials/ModalProgressBar"
import { Bold } from "../../_partials/typography/Bold"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"

export function Modal({ show, onHide, title, children, isLoading, size = "lg" }) {
	return (
		<BootstrapModal
			show={show}
			onHide={!isLoading && onHide}
			data-testid="custom-modal"
			size={size}
			backdrop="static"
		>
			<BootstrapModal.Header>
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div>
						<Bold>{title}</Bold>
					</div>
					<div className="col-auto">
						<IconButton
							disabled={isLoading}
							onClick={onHide}
							style={{ left: "12px" }}
							data-testid="close-button"
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</BootstrapModal.Header>
			<BootstrapModal.Body
				style={{
					padding: "0",
					overflow: "hidden"
				}}
			>
				{isLoading && <ModalProgressBar variant="query" />}
				<div style={{ padding: "0.5rem 1.75rem" }}>{children}</div>
			</BootstrapModal.Body>
		</BootstrapModal>
	)
}
