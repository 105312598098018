export const GROUP_MODAL_TABS = {
	TEXTS: {
		TITLES: {
			SELECT_SITES_AND_GROUPS: "Select Site & Groups",
			CONFIRM_RECIPIENTS: "Confirm Recipients",
			SEND_MESSAGE: "Send Message"
		}
	},
	STYLES: {
		CONTENT_ALIGNMENT_CENTER: "center"
	},
	ICONS: {
		FIRST_TAB_ICON: {
			PATHS: ["M13.2764 11.3364V9.98443H15.9804V19.4614H14.4854V11.3364H13.2764Z"],
			CIRLCE: { cx: "15", cy: "15", r: "15" }
		},
		MESSAGE_HISTORY_ICON: {
			PATHS: [
				"M10.2083 14.7417H1.66663L1.6645 5.30807L8.81081 10.2556C8.91537 10.328 9.03949 10.3667 9.16663 10.3667C9.29376 10.3667 9.41788 10.328 9.52244 10.2556L16.6666 5.30964V9.32503H17.9166V4.7417C17.9162 4.41032 17.7843 4.09263 17.55 3.85831C17.3157 3.62399 16.998 3.49215 16.6666 3.4917H1.66663C1.33522 3.49208 1.0175 3.6239 0.783163 3.85824C0.548825 4.09257 0.417006 4.4103 0.416626 4.7417V14.7417C0.417072 15.0731 0.548912 15.3908 0.783236 15.6251C1.01756 15.8594 1.33524 15.9913 1.66663 15.9917H10.2083V14.7417ZM15.2908 4.7417L9.16663 8.98151L3.0425 4.7417H15.2908Z",
				"M15.4167 18.4915C14.5926 18.4915 13.787 18.2472 13.1018 17.7893C12.4166 17.3315 11.8825 16.6807 11.5672 15.9194C11.2518 15.158 11.1693 14.3202 11.3301 13.512C11.4908 12.7037 11.8877 11.9613 12.4704 11.3786C13.0531 10.7959 13.7955 10.399 14.6038 10.2383C15.412 10.0775 16.2498 10.16 17.0112 10.4754C17.7725 10.7907 18.4233 11.3248 18.8811 12.01C19.339 12.6952 19.5833 13.5008 19.5833 14.3249C19.5833 15.4299 19.1443 16.4897 18.3629 17.2711C17.5815 18.0525 16.5217 18.4915 15.4167 18.4915ZM15.4167 11.1999C14.7986 11.1999 14.1944 11.3831 13.6805 11.7265C13.1666 12.0699 12.7661 12.558 12.5295 13.129C12.293 13.7 12.2311 14.3283 12.3517 14.9345C12.4723 15.5407 12.7699 16.0975 13.207 16.5346C13.644 16.9716 14.2008 17.2692 14.807 17.3898C15.4132 17.5104 16.0415 17.4485 16.6126 17.212C17.1836 16.9755 17.6716 16.5749 18.015 16.061C18.3584 15.5471 18.5417 14.9429 18.5417 14.3249C18.5417 13.4961 18.2124 12.7012 17.6264 12.1152C17.0403 11.5291 16.2455 11.1999 15.4167 11.1999Z",
				"M16.2021 15.9915L14.5834 14.7582V12.6582H15.8334V14.3677L17.0834 15.3201L16.2021 15.9915Z"
			]
		},
		HISTORY_ICON: {
			PATHS: [
				"M17.3187 2.68104C17.2344 2.59709 17.1278 2.53896 17.0116 2.5135C16.8953 2.48803 16.7742 2.49629 16.6625 2.53729L2.9125 7.53729C2.79392 7.58227 2.69183 7.66226 2.61978 7.76664C2.54774 7.87102 2.50916 7.99484 2.50916 8.12167C2.50916 8.24849 2.54774 8.37232 2.61978 8.4767C2.69183 8.58107 2.79392 8.66107 2.9125 8.70604L8.9125 11.106L11.3125 17.106C11.3576 17.2194 11.4349 17.3171 11.5348 17.3871C11.6348 17.457 11.753 17.4962 11.875 17.4998C12.0013 17.4972 12.1239 17.4564 12.2265 17.3828C12.3292 17.3091 12.4071 17.2061 12.45 17.0873L17.45 3.33729C17.4926 3.22672 17.5029 3.10631 17.4796 2.99012C17.4564 2.87393 17.4006 2.76674 17.3187 2.68104ZM11.875 15.1248L10.1312 10.7498L13.125 7.75604L12.2438 6.87479L9.225 9.89354L4.875 8.12479L15.8313 4.16854L11.875 15.1248Z"
			]
		},
		CONTENT_FILL: "#2673F0",
		FONT_SIZE: "28px"
	}
}
