import React from "react"
import { MenuItem, Checkbox, ListItemText, Select as SelectMUI } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
	select: {
		height: "45px",
		width: "100%",
		borderRadius: "4px",
		overflow: "hidden",
		"& .MuiSelect-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	}
}))

const SELECT_ALL_LABEL = "Select All"
const SELECT_OPTIONS_LABEL = "Select All"
const SELECT_ALL_VALUE = "select-all"

export function SelectMultiCheckboxAreas({
	label,
	size,
	options,
	value,
	selectAllEnabled,
	onChange,
	onBlur,
	customColor = "#f4f4f4",
	renderValue,
	...props
}) {
	const classes = useStyles()

	const defaultRenderValue = selected => {
		if (selectAllEnabled && selected.length === options.length) {
			return SELECT_ALL_LABEL
		} else {
			const selectedNames = selected.map(
				val => options.find(option => option.value === val)?.name
			)
			return selectedNames.join(", ") || SELECT_OPTIONS_LABEL
		}
	}

	const selectedValueRenderer = renderValue || defaultRenderValue

	return (
		<>
			<SelectMUI
				multiple
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				style={{ backgroundColor: customColor }}
				className={size ? classes.selectWithSize : classes.select}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 48 * 4.5 + 8,
							width: 250
						}
					}
				}}
				renderValue={selectedValueRenderer}
				{...props}
			>
				{selectAllEnabled && (
					<MenuItem value={SELECT_ALL_VALUE}>
						<Checkbox
							checked={value.length === options.length}
							indeterminate={value.length > 0 && value.length < options.length}
						/>
						<ListItemText primary={SELECT_ALL_LABEL} />
					</MenuItem>
				)}
				{options.map(option => (
					<MenuItem key={option.value} value={option.value}>
						<Checkbox checked={value.indexOf(option.value) > -1} />
						<ListItemText primary={option.name} />
					</MenuItem>
				))}
			</SelectMUI>
		</>
	)
}
