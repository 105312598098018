import React, { useEffect, useState, useMemo } from "react"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import moment from "moment"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../../UIContext"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import SearchIcon from "@material-ui/icons/Search"
import { filterBySearchString } from "../../../Admin/manage-customer/UIHelpers"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { DataGrid } from "../../../../_partials/table/DataGrid"
import RenderRecipientsTableCounter from "../../_partials/RenderRecipientsTableCounter"
import { BUTTONS } from "../../../../_enums/buttons"
import { ERRORS } from "../../../../_enums/errors"

const TABLE_TITLE = "Confirm Recipients"
const ICON_FONT_SIZE = "small"
const SAVE_TEXT = "save"
const SNACKBAR_WARNING_MESSAGE =
	"No group members selected. Please select at least one group member."

const ConfirmTab = ({ handleChange }) => {
	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			sitesValue: UIContext.sitesValue,
			confirmTabRows: UIContext.confirmTabRows,
			groupName: UIContext.groupName,
			membersToSendMessage: UIContext.membersToSendMessage,
			setMembersToSendMessage: UIContext.setMembersToSendMessage
		}
	}, [UIContext])

	const { allPeople, areas, allVendors, customerId } = useSelector(
		state => ({
			customerId: state.profile?.currentCustomer?.id,
			allPeople: state?.manageGroups?.allPeople,
			areas: state.basePage?.areas?.filter(val => val.geoFencing) || [],
			allVendors: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	const [rows, setRows] = useState([])
	const [rowsToUse, setRowsToUse] = useState([])
	const [selectionModel, setSelectionModel] = useState([])
	const [searchText, setSearchText] = useState("")
	const [searchedRows, setSearchedRows] = useState([])

	const columns = [
		{
			field: "name",
			headerName: "Name",
			hideable: false,
			width: 160,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "companyName",
			headerName: "Company",
			type: "string",
			minWidth: 110,
			headerClassName: "header-cell",
			cellClassName: "row-cell"
		},
		{
			field: "role",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			headerName: "Role",
			type: "string",
			minWidth: 70
		},
		{
			field: "uuid",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			headerName: "Tag (uuid)",
			type: "string",
			minWidth: 110
		},
		{
			field: "geofencing",
			headerName: "Geofencing",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120,
			valueGetter: params => {
				const { geofencing } = params.row

				if (!Array.isArray(geofencing) || geofencing.length === 0) {
					return "No Geofencing"
				}

				const selectedGeofencingNames = []
				for (const geofencingId of geofencing) {
					const foundGeofencing = areas.find(area => area.id === geofencingId)
					if (foundGeofencing) {
						selectedGeofencingNames.push(foundGeofencing.name)
					}
				}

				if (selectedGeofencingNames.length === 0) {
					return "Unknown"
				} else if (selectedGeofencingNames.length === areas.length) {
					return "All Selected"
				} else {
					return selectedGeofencingNames.join(", ")
				}
			}
		},
		{
			field: "startDate",
			headerName: "Start Date",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 110,
			renderCell: params => {
				if (!params?.row?.startDate) return ""
				if (
					typeof params?.row?.startDate === "string" ||
					params?.row?.startDate instanceof Date
				) {
					return moment(params?.row?.startDate).format("DD/MM/YYYY")
				}
				return moment(params?.row?.startDate.toDate()).format("DD/MM/YYYY")
			}
		},
		{
			field: "email",
			headerName: "Email",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 250
		},
		{
			field: "phoneNumber",
			headerName: "Phone Number",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140
		},
		{
			field: "helmetId",
			headerName: "Helmet ID",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120
		},
		{
			field: "nationality",
			headerName: "Nationality",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120
		},
		{
			field: "idCard",
			headerName: "ID Number",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 120
		},
		{
			field: "passport",
			headerName: "Passport Number",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140
		},
		{
			field: "driverLicense",
			headerName: "Driver's License",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140
		},
		{
			field: "birthdate",
			headerName: "Date of Birth",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 140,
			renderCell: params => {
				if (!params?.row?.birthdate) return ""
				if (
					typeof params?.row?.birthdate === "string" ||
					params?.row?.birthdate instanceof Date
				) {
					return moment(params?.row?.birthdate).format("DD/MM/YYYY")
				}
				return moment(params?.row?.birthdate.toDate()).format("DD/MM/YYYY")
			}
		},
		{
			field: "emergencyName",
			headerName: "Emergency Name",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 230
		},
		{
			field: "emergencyNumber",
			headerName: "Emergency Contact",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 230
		},
		{
			field: "supervisorName",
			headerName: "Supervisor Name",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 230
		},
		{
			field: "supervisorNumber",
			headerName: "Supervisor Contact",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "string",
			minWidth: 240
		},
		{
			field: "disabled",
			headerName: "Disabled",
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			type: "boolean",
			renderCell: params => {
				const { disabled } = params.row
				if (!disabled) {
					return "false"
				} else {
					return "true"
				}
			}
		}
	]

	const handleSearch = value => {
		setSearchText(value)
	}

	const parsePeopleData = data => {
		return (
			data?.length > 0 &&
			data
				.filter(person => UIProps.confirmTabRows.includes(person.id))
				.map(person => ({
					...person,
					name: person.personalData?.name || "",
					email: person.personalData?.email || "",
					phoneNumber: person.personalData?.phoneNumber || "",
					nationality: person.personalData?.nationality || "",
					passport: person.personalData?.passport || "",
					idCard: person.personalData?.idCard || "",
					driverLicense: person.personalData?.driverLicense || "",
					birthdate: person.personalData?.birthdate || "",
					companyName: allVendors.find(vendor => vendor.id === person.companyId)?.company || ""
				}))
				.sort((a, b) => a.name.localeCompare(b.name))
		)
	}

	useEffect(() => {
		if (!UIProps.confirmTabRows) return

		setRows(parsePeopleData(allPeople))
	}, [UIProps.confirmTabRows])

	useEffect(() => {
		if (!rows) return

		if (!searchText.trim()) {
			setSearchedRows(rows)
			return
		}

		const fieldsToSearch = [
			"personalData.name",
			"personalData.email",
			"personalData.phoneNumber",
			"company",
			"role",
			"uuid",
			"UID"
		]

		setSearchedRows(filterBySearchString(rows, searchText, fieldsToSearch) || [])
	}, [rows, searchText])

	useEffect(() => {
		if (!rows && !searchedRows) return

		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	const handleSubmit = () => {
		if (!customerId) return

		if (rowsToUse?.length === 0) {
			dispatch({
				type: ERRORS.TYPES.SNACKBAR_WARNING,
				payload: {
					message: SNACKBAR_WARNING_MESSAGE
				}
			})
			return
		}

		const members = rowsToUse.filter(person => selectionModel.includes(person.id))
		UIProps.setMembersToSendMessage([...new Set(members)])
	}

	const count = `(${selectionModel.length})`

	return (
		<div style={{ padding: "1rem", backgroundColor: "#FFFFFF" }} data-testid="confirm-tab">
			<div className="d-flex justify-content-between pt-4">
				<RenderRecipientsTableCounter title={TABLE_TITLE} count={count} />

				<div className="col" style={{ display: "flex", placeContent: "end" }}>
					<SearchBarCustom1
						onSearch={handleSearch}
						searchText={searchText}
						disabled={rows?.length === 0}
						style={{
							width: "200px",
							background: "#F2F2F2",
							marginRight: "10px"
						}}
						InputProps={{
							startAdornment: (
								<SearchIcon
									fontSize={ICON_FONT_SIZE}
									style={{
										color: "#7F8C8D",
										marginRight: "0.7rem"
									}}
								/>
							)
						}}
					/>
				</div>
			</div>
			<DataGrid
				rows={searchedRows}
				columns={columns}
				checkboxSelection
				paginationMode="server"
				onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
				selectionModel={selectionModel}
				getRowClassName={params => (params.row.disabled ? "disabled-row" : "")} //apply disable-row class only to disabled people
				hideFooter={true}
			/>
			<div className="row justify-content-between mt-2" style={{ padding: "0 1rem" }}>
				<Button
					color={BUTTONS.COLORS.PRIMARY}
					variant={BUTTONS.VARIANTS.OUTLINED}
					startIcon={<KeyboardArrowLeftIcon fontSize={ICON_FONT_SIZE} />}
					onClick={e => handleChange(e, 0)}
				/>
				<Button
					color={BUTTONS.COLORS.PRIMARY}
					variant={BUTTONS.VARIANTS.CONTAINED}
					text={SAVE_TEXT}
					disabled={!selectionModel.length}
					onClick={e => {
						handleSubmit()
						handleChange(e, 2)
					}}
				/>
			</div>
		</div>
	)
}

export default ConfirmTab
