import React from "react"
import {
	Checkbox,
	ListItem,
	ListItemText,
	Grid,
	List,
	ListItemSecondaryAction,
	IconButton
} from "@mui/material"
import { makeStyles } from "@material-ui/core"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Header } from "../NestedOptionsDropdownHeader"

const useStyles = makeStyles(theme => ({
	selectAllCheckbox: {
		paddingTop: "4.5px !important"
	},

	counter: {
		fontSize: "12px",
		color: "#8C8C8A",
		marginLeft: theme.spacing(1)
	}
}))

const COLOR_PRIMARY = "primary"
const HEADER_TITLE = "Groups"
const ICON_EDGE = "end"
const ICON_ARIAL_LABEL = "more"

export const RenderMainPopover = ({
	options,
	selectedValues,
	setSelectedValues,
	allGroupsSelected,
	setAllGroupsSelected,
	setSubOptions,
	setCurrentGroupLabel,
	setIsMainPopover,
	isSelected,
	groupsCounter,
	searchTerm,
	setSearchTerm,
	hasSearchBox
}) => {
	const classes = useStyles()

	const handleSelectAllGroups = () => {
		const allGroupItems = options.flatMap(option =>
			Array.isArray(option.subOptions) ? option.subOptions.map(sub => sub.name) : [option.label]
		)

		const shouldSelectAll = !allGroupItems.every(item => {
			return options.some(group => selectedValues[group.label]?.includes(item))
		})

		const newSelected = shouldSelectAll
			? options.reduce((acc, group) => {
					const groupItems = group.subOptions?.map(option => option.name) || []
					return {
						...acc,
						[group.label]: groupItems
					}
			  }, {})
			: {}

		setAllGroupsSelected(shouldSelectAll)
		setSelectedValues(newSelected)
	}

	const handleGroupCheckboxChange = (groupLabel, subOptions) => {
		const groupItems = subOptions.map(option => option.name)
		const shouldSelectAll = !groupItems.every(item => selectedValues[groupLabel]?.includes(item))

		const newSelected = { ...selectedValues }
		if (shouldSelectAll) {
			newSelected[groupLabel] = groupItems
		} else {
			delete newSelected[groupLabel]
		}

		setSelectedValues(newSelected)
	}

	const handleGroupClick = group => {
		const selectedGroup = options.find(option => option.label === group.label)
		if (selectedGroup) {
			setSubOptions(selectedGroup.subOptions || [])
			setCurrentGroupLabel(group.label)
			setIsMainPopover(false)
		}
	}

	const getSelectedCountForGroup = group => {
		const groupItems = group.subOptions?.map(option => option.name) || []
		const selectedCount = groupItems.filter(item => isSelected(group.label, item)).length
		return { selectedCount, totalCount: groupItems.length }
	}

	return (
		<>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={1}>
					<Checkbox
						checked={allGroupsSelected}
						onChange={handleSelectAllGroups}
						color={COLOR_PRIMARY}
						className={classes.selectAllCheckbox}
					/>
				</Grid>
				<Grid item xs={11}>
					<Header
						title={HEADER_TITLE}
						subtitle={`${groupsCounter}/${options.length} selected`}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						onBackClick={handleSelectAllGroups}
						icon={Checkbox}
						hasSearchBox={hasSearchBox}
						showIcon={false}
					/>
				</Grid>
			</Grid>

			<List>
				{options.map(group => {
					const { selectedCount, totalCount } = getSelectedCountForGroup(group)
					return (
						<ListItem key={group.label} style={{ paddingLeft: "0", paddingRight: "0" }}>
							<Checkbox
								checked={
									!!group.subOptions?.every(option => isSelected(group.label, option.name))
								}
								color={COLOR_PRIMARY}
								onChange={() => handleGroupCheckboxChange(group.label, group.subOptions)}
							/>
							<ListItemText
								primary={
									<span style={{ display: "flex", alignItems: "center" }}>
										{group.label}
										<span className={classes.counter}>
											{selectedCount}/{totalCount} selected
										</span>
									</span>
								}
							/>
							<ListItemSecondaryAction>
								<IconButton
									edge={ICON_EDGE}
									aria-label={ICON_ARIAL_LABEL}
									onClick={() => handleGroupClick(group)}
								>
									<ArrowForwardIosIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					)
				})}
			</List>
		</>
	)
}
