import React, { useEffect, useMemo } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useUIContext } from "../../../UIContext"
import { SelectMultiCheckboxAreas } from "../../../../../_partials/inputs/SelectMultiCheckboxAreas"
import SelectedItemsCounter from "../../../../../_partials/SelectedItemsCounter"

const NO_SITE = "No Site"
const SITES_LABEL = "Sites"
const SELECT_ALL = "select-all"

const SitesFilter = ({ sitesValue, setSitesValue, groupName }) => {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			sitesValue: UIContext.sitesValue,
			groupId: UIContext.groupId,
			setSitesValue: UIContext.setSitesValue,
			setGroupName: UIContext.setGroupName,
			setSelectedValues: UIContext.setSelectedValues
		}
	}, [UIContext])

	const { currentCustomer, allSites, selectedSite } = useSelector(
		state => ({
			currentCustomer: state?.profile?.currentCustomer,
			allSites: state.manageSites.sites,
			selectedSite: state.profile?.currentSite
		}),
		shallowEqual
	)

	const options = useMemo(
		() => [
			{
				name: NO_SITE,
				value: false
			},
			...currentCustomer.sites.map(site => {
				return {
					name: site.name,
					value: site.id
				}
			})
		],
		[currentCustomer, allSites]
	)

	const isSelectAll = sitesValue?.length === options?.length

	const handleSelectAllToggle = () => {
		if (isSelectAll) {
			setSitesValue([])
		} else {
			setSitesValue(options.map(option => option.value))
		}
	}

	const handleSelectMultipleCheckbox = selectedValues => {
		UIProps.setSelectedValues([])

		if (selectedValues.includes(SELECT_ALL)) {
			return handleSelectAllToggle()
		}

		setSitesValue(selectedValues)
	}

	useEffect(() => {
		if (!groupName) return
		UIProps.setGroupName(groupName)
	}, [])

	useEffect(() => {
		if (!sitesValue) return

		UIProps.setSitesValue(sitesValue)
	}, [sitesValue])

	useEffect(() => {
		if (!options) return

		if (UIProps?.sitesValue?.length > 0) return

		const match = selectedSite
			? options.filter(option => option.value === selectedSite.id).map(option => option.value)
			: options.map(option => option.value)

		setSitesValue?.(match)
	}, [options, selectedSite])

	return (
		<div data-testid="sites-filter">
			<SelectMultiCheckboxAreas
				disabled={!options.length > 0}
				value={sitesValue || []}
				options={options}
				selectAllEnabled
				onChange={e => {
					handleSelectMultipleCheckbox(e.target.value)
				}}
			/>

			<div>
				<SelectedItemsCounter
					selectedItems={sitesValue}
					allItems={allSites}
					label={SITES_LABEL}
				/>
			</div>
		</div>
	)
}

export default SitesFilter
