import React from "react"

/**
 * Resends a notification message for a selected customer.
 *
 * @async
 * @param {string} id - The ID of the message to be resent.
 * @param {Object} selectedCustomer - The selected customer object, containing an `id` property.
 * @param {Object} user - The user object, containing `id` and `tenantId` properties.
 * @param {Function} dispatch - Function to dispatch actions for state management.
 * @param {Function} setIsResending - Function to toggle the resending state.
 * @returns {Promise<void>} Resolves when the operation is complete.
 *
 * @throws Dispatches a "SNACKBAR_ERROR" action with appropriate messages for error scenarios.
 */

export async function useResendMessage({ id, selectedCustomer, user, dispatch, setIsResending }) {
	if (!id || !selectedCustomer || !user) {
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: {
				message: "Unable to resend message. Please try again later."
			}
		})
		return
	}

	setIsResending(true)

	const myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		messageId: id,
		user: { callersUserId: user.id, callersTenantId: user.tenantId }
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	try {
		const response = await fetch(
			`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customers/${selectedCustomer.id}/communication/resend-notification`,
			requestOptions
		)

		const result = await response.json()

		if (response.ok) {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: result
				}
			})
		} else {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: result
				}
			})
		}
	} catch (error) {
		console.error("Unexpected error:", error)
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: {
				message: "An unexpected error occurred. Please try again later."
			}
		})
	} finally {
		setIsResending(false)
	}
}
