import React from "react"
import PropTypes from "prop-types"

export const renderTabContent = (content, index, { value, theme }) => (
	<TabPanel key={index} value={value} index={index} dir={theme.direction}>
		{content}
	</TabPanel>
)

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}
