import React from "react"

export const RenderRecipientsTableCounter = ({ title, count }) => {
	return (
		<div style={{ padding: "1rem 0" }}>
			<span
				style={{
					color: "#444",
					fontSize: "16px",
					fontStyle: "normal",
					fontWeight: "600",
					lineHeight: "160%"
				}}
			>
				{title} {count}
			</span>
		</div>
	)
}

export default RenderRecipientsTableCounter
